<template>
  <div>
    <deliveries-sub-header
      v-if="$route.name === 'project.details.deliveries'"
      class="vms-project-sub-header vd-border-light-grey vd-position-relative"
    ></deliveries-sub-header>
  </div>
</template>

<script>
import DeliveriesSubHeader from '@views/project/components/sub-headers/deliveries-sub-header'

export default {
  components: {
    DeliveriesSubHeader,
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/project-sub-headers';
</style>
