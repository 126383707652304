<template>
  <filters-dropdown
    :class="[`vd-margin-right-medium vd-display-inline-block`, customClass]"
    :selected-text="selectedSortOption.text"
    :dropdown-options="sortOptions"
    :custom-dropdown-list-class="customDropdownListClass"
    @handle:option-select="handleSortOptionSelect"
  ></filters-dropdown>
</template>

<script>
import FiltersDropdown from '@components/filters/filter-dropdown'
import { find } from 'lodash'

export default {
  components: {
    FiltersDropdown,
  },

  props: {
    sortOptions: {
      type: Array,
      required: true,
      default: () => [],
    },

    customClass: {
      type: String,
      required: false,
      default: () => '',
    },

    customDropdownListClass: {
      type: String,
      required: false,
      default: () => '',
    },
  },

  data() {
    return {
      selectedSortOption: {},
    }
  },

  beforeMount() {
    this.selectedSortOption = find(this.sortOptions, (sortOption) => {
      return sortOption.selected
    })
  },

  methods: {
    handleSortOptionSelect(sortOption) {
      this.selectedSortOption = sortOption
      this.$emit('update:sort', sortOption)
    },
  },
}
</script>
