<template>
  <div
    class="vms-project-tab__header container-fluid vd-background-aqua vd-padding-top-small vd-padding-bottom-small"
  >
    <div class="row no-gutters vms-project-tab__controls-wrapper">
      <div
        :class="[
          'row no-gutters vms-project-tab__filters',
          isMobileScreen
            ? 'vms-project-tab__filters--mobile vd-margin-top-extra-small vd-margin-bottom-small'
            : 'vd-margin-left-small vd-margin-right-small',
        ]"
      >
        <search-component
          :custom-class="`vms-project-tab__filters--search`"
          :search-text="deliveriesPageFilters.search"
          @enter:search="deliveriesSearchUpdate"
        >
        </search-component>
        <div
          :class="[
            isMobileScreen
              ? 'vd-margin-top-extra-small vd-margin-bottom-small'
              : 'vd-margin-left-small vd-margin-right-small',
          ]"
        >
          <sort-component
            :sort-options="sortOptions"
            :custom-class="`vms-project-tab__filters--sort`"
            :custom-dropdown-list-class="`vms-project-tab__filters--sort__option`"
            @update:sort="deliveriesSortUpdate"
          >
          </sort-component>
        </div>
        <div
          id="clearDeliveriesFilterDiv"
          class="vd-padding-small vd-display-inline-block vd-margin-bottom-extra-small vms-project-tab__filters--clear"
          @click="resetFilter"
        >
          <span class="vd-light-blue">Clear</span>
        </div>
      </div>

      <div
        :class="[
          'vms-project-tab__pagination vd-margin-top-medium vd-margin-bottom-medium',
          isMobileScreen ? 'vd-margin-right-50' : 'vd-margin-right-70',
        ]"
      >
        <pagination-component
          :total-rows="deliveries.meta.total"
          :per-page="deliveries.meta.per_page"
          :last-page="deliveries.meta.last_page"
          :show-page-in-route="false"
          :page="deliveries.meta.current_page"
          @update:page="currentPageUpdate"
          @update:per_page="deliveriesPerPageUpdate"
        ></pagination-component>
      </div>
    </div>

    <div
      :class="[
        'vms-project__add--container',
        isMediumScreen
          ? 'vms-project__add--container__mobile vd-margin-bottom-small'
          : '',
      ]"
    >
      <div
        id="addDeliveriesIconDiv"
        :class="[
          'vd-icon-plus-round-blue vms-project__add--icon',
          isMediumScreen ? 'vms-project__add--icon__mobile' : '',
        ]"
        @click="handleAddVideoDelivery"
      ></div>

      <div
        v-show="showDeliveriesDropdown"
        :class="[
          'vms-dropdown vms-project__add--dropdown',
          isMediumScreen ? 'vms-project__add--dropdown__mobile' : '',
        ]"
      >
        <div class="vd-box-shadow vd-background-white vd-border-radius-6">
          <div
            v-for="videoProduct in videoProducts"
            :id="getDeliveriesDropdownId(videoProduct.name)"
            :key="videoProduct.id"
            class="vms-dropdown__list vd-black vd-padding-small"
            @click="handleVideoProductClick(videoProduct.product_id)"
          >
            {{ videoProduct.name }}
          </div>
          <div
            v-if="canShowBulkVideoOption"
            id="addBulkVideosDiv"
            class="vms-dropdown__list vd-black vd-padding-small"
            @click="handleAddBulkVideos"
          >
            Bulk Videos
          </div>
        </div>
      </div>
    </div>

    <bulk-add-videos-modal
      v-if="showBulkAddVideosModal"
    ></bulk-add-videos-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  startCase,
  camelCase,
  cloneDeep,
  isEmpty,
  isEqual,
  toString,
} from 'lodash'
import SweetAlert from '@plugins/sweet-alert'
import PaginationComponent from '@components/pagination/pagination'
import ApiCustomIncludes from '@configs/api-custom-includes'
import BulkAddVideosModal from '@views/project/deliveries/components/deliveries-bulk-add-videos/deliveries-bulk-add-videos'
import SortComponent from '@components/sort/sort'
import SortOptions from '@configs/sort-options'
import SearchComponent from '@components/search/search'

export default {
  components: {
    PaginationComponent,
    BulkAddVideosModal,
    SortComponent,
    SearchComponent,
  },

  data() {
    return {
      sortOptions: SortOptions.deliveries,
      showDeliveriesDropdown: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      deliveries: 'project/delivery/deliveries',
      videoProducts: 'project/delivery/videoProducts',
      videoDeliveryJobNewFormOpen:
        'project/delivery/videoDeliveryJobNewFormOpen',
      videoDeliveryJobEditFormOpen:
        'project/delivery/videoDeliveryJobEditFormOpen',
      hasProjectProductType: 'project/hasProjectProductType',
      showBulkAddVideosModal: 'project/delivery/showBulkAddVideosModal',
      isMobileScreen: 'common/isMobileScreen',
      deliveriesPageFilters: 'project/delivery/deliveriesPageFilters',
      isProjectOnHold: 'project/isProjectOnHold',
      isMediumScreen: 'common/isMediumScreen',
    }),

    canShowBulkVideoOption() {
      return this.project.product_type && this.project.product_type.id
    },
  },

  mounted() {
    if (this.hasProjectProductType) {
      this.getProjectVideoProducts({
        projectId: this.project.id,
        include: ApiCustomIncludes.deliveriesList,
      })
    }

    // Filter and show only the delivery job if show_only=job_id is supplied in the route.
    if (!isEmpty(toString(this.$route.query.show_only))) {
      this.deliveriesSearchUpdate(this.$route.query.show_only)

      // Remove query params after calling the showOnlyDeliveryJob Method.
      window.history.replaceState(null, null, this.$route.path)
    }
  },

  methods: {
    ...mapActions({
      getProjectDeliveriesList: 'project/delivery/getProjectDeliveriesList',
      getProjectVideoProducts: 'project/delivery/getProjectVideoProducts',
      showAddNewVideoDeliveryJobForm:
        'project/delivery/showAddNewVideoDeliveryJobForm',
      showEditVideoDeliveryJobForm:
        'project/delivery/showEditVideoDeliveryJobForm',
      updateSelectedVideoProductId:
        'project/delivery/updateSelectedVideoProductId',
      setSelectedVideoProductId: 'project/delivery/setSelectedVideoProductId',
      toggleBulkAddVideosShowModal:
        'project/delivery/toggleBulkAddVideosShowModal',
      setBulkAddVideosQuantity: 'project/delivery/setBulkAddVideosQuantity',
      setBulkAddVideosNames: 'project/delivery/setBulkAddVideosNames',
      setDeliveriesPageFilters: 'project/delivery/setDeliveriesPageFilters',
      updateSelectedVideoDeliveryJobId:
        'project/delivery/updateSelectedVideoDeliveryJobId',
    }),

    currentPageUpdate(page) {
      // check if the selected page is not more than the meta last page
      const updatedPage =
        page <= this.deliveries.meta.last_page
          ? page
          : this.deliveries.meta.last_page

      this.getProjectDeliveriesList({
        projectId: this.project.id,
        include: ApiCustomIncludes.deliveriesList,
        page: updatedPage,
      })
    },

    deliveriesPerPageUpdate(perPage) {
      this.getProjectDeliveriesList({
        projectId: this.project.id,
        include: ApiCustomIncludes.deliveriesList,
        perPage,
        page: 1,
      })
    },

    deliveriesSortUpdate(sortOption) {
      this.setDeliveriesPageFilters({
        sort: sortOption.sort,
        sortBy: sortOption.sortBy,
      })
      this.getProjectDeliveriesList({
        projectId: this.project.id,
        include: ApiCustomIncludes.deliveriesList,
        page: 1,
      })
    },

    deliveriesSearchUpdate(search) {
      this.setDeliveriesPageFilters({
        search: search,
      })
      this.getProjectDeliveriesList({
        projectId: this.project.id,
        include: ApiCustomIncludes.deliveriesList,
        page: 1,
      })
    },

    handleAddVideoDelivery() {
      if (this.videoDeliveryJobNewFormOpen) {
        return
      }

      if (!this.hasProjectProductType || this.isProjectOnHold) {
        this.showDeliveriesDropdown = false

        const title = !this.hasProjectProductType
          ? 'Missing Product Type'
          : 'Project On Hold'
        const content = !this.hasProjectProductType
          ? `<div class="vd-margin-top-small">This project is missing a Product Type. <br><br> Before adding a production job please select a Product Type first.</div>`
          : `<div class="vd-margin-top-small">Take project off hold before adding a delivery job.</div>`

        SweetAlert.fire({
          title: title,
          html: content,
          showCancelButton: !this.hasProjectProductType,
          confirmButtonText: 'Okay',
        }).then((result) => {
          if (result.isConfirmed && !this.hasProjectProductType) {
            this.$router.push({
              name: 'project.details.summary',
              params: {
                projectId: this.project.id,
              },
            })
          }
        })
        return
      }

      this.showDeliveriesDropdown = !this.showDeliveriesDropdown
    },

    handleVideoProductClick(videoProductId) {
      if (this.videoDeliveryJobEditFormOpen) {
        this.showWarningAlert(videoProductId)
      } else {
        this.openVideoDeliveryForm(videoProductId)
      }

      this.showDeliveriesDropdown = false
    },

    openVideoDeliveryForm(videoProductId) {
      this.updateSelectedVideoProductId({
        product_id: videoProductId,
        type: 'create',
      })
      this.updateSelectedVideoDeliveryJobId(null)
      this.showAddNewVideoDeliveryJobForm(true)
      this.showEditVideoDeliveryJobForm(false)

      this.showDeliveriesDropdown = false
    },

    showWarningAlert(videoProductId) {
      SweetAlert.fire({
        title: 'Unsaved Changes',
        html: `<br> You have unsaved changes in progress. <br><br> Do you wish to discard them?`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.openVideoDeliveryForm(videoProductId)
        }
      })
    },

    getDeliveriesDropdownId(name) {
      return `createDeliveries${startCase(camelCase(name)).replace(
        / /g,
        ''
      )}Div`
    },

    handleAddBulkVideos() {
      this.toggleBulkAddVideosShowModal()
      this.setBulkAddVideosNames([])
      this.setBulkAddVideosQuantity(1)
      this.setSelectedVideoProductId(null)

      this.showDeliveriesDropdown = false
    },

    resetFilter() {
      if (!isEmpty(toString(this.$route.query.show_only))) {
        this.$router.push({
          name: 'project.details.deliveries',
          params: {
            projectId: this.project.id,
          },
        })
      }

      const defaultFilters = {
        sort: 'desc',
        sortBy: 'created_at',
        search: '',
      }

      if (isEqual(defaultFilters, cloneDeep(this.deliveriesPageFilters))) {
        return
      }

      this.setDeliveriesPageFilters(defaultFilters)
      this.getProjectDeliveriesList({
        projectId: this.project.id,
        include: ApiCustomIncludes.deliveriesList,
        page: 1,
      })
    },
  },
}
</script>
