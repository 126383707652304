<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <h2
          :class="[
            'vd-light-blue vms-project__heading--title',
            isMobileScreen
              ? 'col-12 vd-h6 d-inline-block vd-margin-top-extra-small vd-margin-bottom-extra-small'
              : 'vd-h2',
          ]"
        >
          <span>{{ project.name | truncateString(40) }}</span>
        </h2>

        <p v-if="isMobileScreen" class="col-12 vd-margin-bottom-small">
          <span class="vd-dark-grey vd-margin-right-extra-small">Office:</span>
          <a class="vd-a" target="blank" :href="officeUrl">{{
            project.office.name | truncateString(40)
          }}</a>
        </p>

        <div
          v-if="!isMobileScreen"
          class="vd-padding-left-10 vd-padding-right-10"
        >
          <span class="vd-dark-grey vd-h2">|</span>
        </div>

        <div :class="isMobileScreen ? 'col-10' : 'vd-display-inline-block'">
          <div class="row no-gutters">
            <project-tooltip
              v-if="canShowTooltip"
              :display-project-id="!isProjectReopen"
              :display-project-details="canDisplayProjectDetails"
              :display-project-info="isMobileScreen"
            ></project-tooltip>

            <div
              v-if="hasProjectStatus"
              class="vd-margin-left-medium vd-margin-top-extra-small"
            >
              <project-status
                :project-status="project.project_status"
              ></project-status>
            </div>
          </div>
        </div>

        <project-header-dropdown
          v-if="isMobileScreen"
          class="col-2"
        ></project-header-dropdown>
      </div>

      <project-info
        v-if="!isMobileScreen"
        :display-project-id="!isProjectReopen"
        :display-project-details="canDisplayProjectDetails"
      ></project-info>
    </div>

    <project-health
      v-if="canDisplayProjectDetails"
      class="vms-project-health__content vd-margin-left-small vd-margin-right-small"
    ></project-health>

    <eod-notes-modal
      v-if="showEodNotesModal"
      :project-id="project.id"
      :project-name="project.name"
    >
    </eod-notes-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isNil } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'
import ProjectHealth from '@views/project/components/project-health/project-health'
import ProjectStatus from '@components/project-status/project-status'
import ProjectTooltip from '@views/project/components/project-header/project-tooltip'
import ProjectInfo from '@views/project/components/project-header/project-info'
import ProjectHeaderDropdown from '@views/project/components/project-header/project-header-dropdown'
import EodNotesModal from '@components/eod-notes/eod-notes-modal'

export default {
  components: {
    ProjectHealth,
    ProjectStatus,
    ProjectTooltip,
    ProjectInfo,
    ProjectHeaderDropdown,
    EodNotesModal,
  },

  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      hasOrders: 'project/hasOrders',
      isMobileScreen: 'common/isMobileScreen',
      officeUrl: 'project/projectOfficeUrl',
      showEodNotesModal: 'project/showEodNotesModal',
    }),

    isProjectReopen() {
      return this.$route.name === 'project.details.reopen'
    },

    canDisplayProjectDetails() {
      return !isNil(this.project.id) && !this.isProjectReopen
    },

    hasProjectStatus() {
      return !isNil(this.project.project_status_id)
    },

    canShowTooltip() {
      return (
        this.isMobileScreen || (this.canDisplayProjectDetails && this.hasOrders)
      )
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/project-header';
</style>
