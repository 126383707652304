var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-project-tab__header container-fluid vd-background-aqua vd-padding-top-small vd-padding-bottom-small"},[_c('div',{staticClass:"row no-gutters vms-project-tab__controls-wrapper"},[_c('div',{class:[
        'row no-gutters vms-project-tab__filters',
        _vm.isMobileScreen
          ? 'vms-project-tab__filters--mobile vd-margin-top-extra-small vd-margin-bottom-small'
          : 'vd-margin-left-small vd-margin-right-small' ]},[_c('search-component',{attrs:{"custom-class":"vms-project-tab__filters--search","search-text":_vm.deliveriesPageFilters.search},on:{"enter:search":_vm.deliveriesSearchUpdate}}),_c('div',{class:[
          _vm.isMobileScreen
            ? 'vd-margin-top-extra-small vd-margin-bottom-small'
            : 'vd-margin-left-small vd-margin-right-small' ]},[_c('sort-component',{attrs:{"sort-options":_vm.sortOptions,"custom-class":"vms-project-tab__filters--sort","custom-dropdown-list-class":"vms-project-tab__filters--sort__option"},on:{"update:sort":_vm.deliveriesSortUpdate}})],1),_c('div',{staticClass:"vd-padding-small vd-display-inline-block vd-margin-bottom-extra-small vms-project-tab__filters--clear",attrs:{"id":"clearDeliveriesFilterDiv"},on:{"click":_vm.resetFilter}},[_c('span',{staticClass:"vd-light-blue"},[_vm._v("Clear")])])],1),_c('div',{class:[
        'vms-project-tab__pagination vd-margin-top-medium vd-margin-bottom-medium',
        _vm.isMobileScreen ? 'vd-margin-right-50' : 'vd-margin-right-70' ]},[_c('pagination-component',{attrs:{"total-rows":_vm.deliveries.meta.total,"per-page":_vm.deliveries.meta.per_page,"last-page":_vm.deliveries.meta.last_page,"show-page-in-route":false,"page":_vm.deliveries.meta.current_page},on:{"update:page":_vm.currentPageUpdate,"update:per_page":_vm.deliveriesPerPageUpdate}})],1)]),_c('div',{class:[
      'vms-project__add--container',
      _vm.isMediumScreen
        ? 'vms-project__add--container__mobile vd-margin-bottom-small'
        : '' ]},[_c('div',{class:[
        'vd-icon-plus-round-blue vms-project__add--icon',
        _vm.isMediumScreen ? 'vms-project__add--icon__mobile' : '' ],attrs:{"id":"addDeliveriesIconDiv"},on:{"click":_vm.handleAddVideoDelivery}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDeliveriesDropdown),expression:"showDeliveriesDropdown"}],class:[
        'vms-dropdown vms-project__add--dropdown',
        _vm.isMediumScreen ? 'vms-project__add--dropdown__mobile' : '' ]},[_c('div',{staticClass:"vd-box-shadow vd-background-white vd-border-radius-6"},[_vm._l((_vm.videoProducts),function(videoProduct){return _c('div',{key:videoProduct.id,staticClass:"vms-dropdown__list vd-black vd-padding-small",attrs:{"id":_vm.getDeliveriesDropdownId(videoProduct.name)},on:{"click":function($event){return _vm.handleVideoProductClick(videoProduct.product_id)}}},[_vm._v(" "+_vm._s(videoProduct.name)+" ")])}),(_vm.canShowBulkVideoOption)?_c('div',{staticClass:"vms-dropdown__list vd-black vd-padding-small",attrs:{"id":"addBulkVideosDiv"},on:{"click":_vm.handleAddBulkVideos}},[_vm._v(" Bulk Videos ")]):_vm._e()],2)])]),(_vm.showBulkAddVideosModal)?_c('bulk-add-videos-modal'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }