import { render, staticRenderFns } from "./deliveries-bulk-add-videos-names.vue?vue&type=template&id=0c850d81&"
import script from "./deliveries-bulk-add-videos-names.vue?vue&type=script&lang=js&"
export * from "./deliveries-bulk-add-videos-names.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports