<template>
  <main-layout>
    <project-confirm-contact
      v-if="showConfirmContactPopup"
      slot="content-body"
    ></project-confirm-contact>
    <div
      v-else
      slot="content-body"
      :class="[
        'vms-project__content-wrapper',
        isMobileScreen ? 'vms-project__content-wrapper--mobile' : '',
      ]"
    >
      <div class="vms-project__contents vd-position-relative">
        <default-loader
          v-if="projectDetailsLoading"
          class="vms-loader__visdom-shield--full"
        ></default-loader>

        <div
          v-if="!projectDetailsLoading"
          :class="[!isMobileScreen ? 'vms-content__container' : '']"
        >
          <div
            class="row no-gutters vd-position-relative vd-padding-left-medium vd-padding-right-medium"
          >
            <project-breadcrumbs
              :class="isMobileScreen ? 'col-12' : 'col-7'"
            ></project-breadcrumbs>

            <project-actions
              v-if="!isMobileScreen"
              class="vms-project__actions vd-text-align-right col-5"
            ></project-actions>

            <project-header class="col-12"></project-header>
          </div>

          <project-menu-tab
            :class="[
              'vd-padding-left-medium',
              isMobileScreen
                ? 'vd-margin-top-medium'
                : 'vd-margin-top-60 vd-padding-right-medium',
            ]"
          ></project-menu-tab>

          <project-tab-header></project-tab-header>

          <project-sub-header></project-sub-header>
        </div>

        <keep-alive>
          <router-view v-if="!projectDetailsLoading"></router-view>
        </keep-alive>

        <project-health-modal
          v-if="showProjectHealthModal"
          :class="{ 'vms-project-health-modal--mobile': isMobileScreen }"
        ></project-health-modal>
      </div>
    </div>

    <project-bottom-menu
      v-if="isMobileScreen && !projectDetailsLoading"
      slot="content-footer"
    ></project-bottom-menu>
  </main-layout>
</template>

<script>
import MainLayout from '@layouts/main-layout'
import { mapGetters, mapActions } from 'vuex'
import { filter, forEach, isEmpty, isNull, merge, values, size } from 'lodash'
import ProjectBreadcrumbs from '@views/project/components/project-breadcrumbs/project-breadcrumbs'
import ProjectHeader from '@views/project/components/project-header/project-header'
import ProjectMenuTab from '@views/project/components/project-menu-tab'
import ProjectTabHeader from '@views/project/components/tab-headers/project-tab-header'
import ProjectSubHeader from '@views/project/components/sub-headers/project-sub-header'
import ProjectConfirmContact from '@views/project/components/project-confirm-contact-modal'
import DefaultLoader from '@components/loaders/default-loader'
import ApiCustomIncludes from '@configs/api-custom-includes'
import VmsConstants from '@configs/vms-constants'
import ProjectActions from '@views/project/components/project-actions'
import ProjectBottomMenu from '@views/project/components/project-bottom-menu'
import ProjectHealthModal from '@views/project/components/project-health/project-health-modal'

export default {
  components: {
    MainLayout,
    ProjectBreadcrumbs,
    ProjectHeader,
    ProjectMenuTab,
    ProjectTabHeader,
    ProjectSubHeader,
    ProjectConfirmContact,
    DefaultLoader,
    ProjectBottomMenu,
    ProjectActions,
    ProjectHealthModal,
  },

  metaInfo() {
    return {
      title:
        this.project && this.project.id
          ? `${this.project.id} | ${this.project.name} | ${this.$route.meta.title}`
          : `Project | ${this.$route.meta.title}`,
    }
  },

  computed: {
    ...mapGetters({
      windowWidth: 'common/windowWidth',
      projectDetailsLoading: 'project/projectDetailsLoading',
      project: 'project/projectDetails',
      createNewProjectLoading: 'project/createNewProjectLoading',
      isProjectCancelled: 'project/isProjectCancelled',
      contacts: 'project/contact/projectContacts',
      showProjectHealthModal: 'project/project-health/showProjectHealthModal',
      isMobileScreen: 'common/isMobileScreen',
      internalUsersList: 'user/internalUsersList',
    }),

    projectId() {
      return this.$route.params.projectId
    },

    showConfirmContactPopup() {
      const husbpotOrder = filter(this.project.project_orders, (order) => {
        return !isNull(order.hubspot_deal_id)
      })

      return (
        !this.project.is_contact_confirmed &&
        this.project.project_status_id ===
          VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT &&
        !isEmpty(husbpotOrder) &&
        size(this.officeList) > 1
      )
    },

    officeList() {
      const offices = []
      forEach(this.contacts, (contact) => {
        merge(offices, contact.offices)
      })

      return filter(values(offices), (office) => {
        return office.is_active
      })
    },
  },

  mounted() {
    this.getProjectDetails({
      projectId: this.projectId,
      customIncludes: ApiCustomIncludes.projectDetails,
    })
    if (isEmpty(this.internalUsersList)) {
      this.getInternalUsers({
        includes: ApiCustomIncludes.eventsUser,
      })
    }
  },

  methods: {
    ...mapActions({
      getProjectDetails: 'project/getProjectDetails',
      getInternalUsers: 'user/getInternalUsers',
    }),
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/project-main';
</style>
