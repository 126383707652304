<template>
  <div
    id="showProjectHealthModalDiv"
    class="vd-display-inline-block"
    @click="toggleProjectHealthModalShow"
  >
    <text-popover
      class="vd-display-inline-block"
      tooltip-text="Project health"
      tooltip-icon="vd-icon-health-circle"
      tooltip-id="projectHealthTooltip"
    ></text-popover>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
export default {
  components: {
    TextPopover,
  },

  methods: {
    ...mapActions({
      toggleProjectHealthModalShow:
        'project/project-health/toggleProjectHealthModalShow',
    }),
  },
}
</script>
