<template>
  <div
    :class="[
      'vms-project-tab__header vms-project-tab__header--files vd-border-light-grey vd-background-aqua row no-gutters',
      isMobileScreen ? 'vms-project-tab__header--files__mobile' : '',
    ]"
  >
    <div
      v-if="!isMediumScreen"
      class="vms-project-tab__header--files-left vd-dark-grey vd-padding-medium vd-border-light-grey"
    >
      Uploaded Files
    </div>

    <project-sidebar v-else sidebar-type="uploadedFiles"></project-sidebar>

    <hr
      v-if="isMobileScreen"
      class="vd-border-grey vd-full-width vd-margin-0"
    />

    <div
      class="vms-project-tab__header--files-right vd-dark-grey vd-padding-medium"
    >
      <pagination-component
        v-if="isProjectFilesRoute"
        :total-rows="projectFiles.meta.total"
        :per-page="projectFiles.meta.per_page"
        :last-page="projectFiles.meta.last_page"
        @update:page="projectFilesPageUpdate"
        @update:per_page="projectFilesPerPageUpdate"
      ></pagination-component>
      <pagination-component
        v-else-if="isBrandHubRoute"
        :total-rows="brandHub.meta.total"
        :per-page="brandHub.meta.per_page"
        :last-page="brandHub.meta.last_page"
        @update:page="brandHubPageUpdate"
        @update:per_page="brandHubPerPageUpdate"
      ></pagination-component>
    </div>
  </div>
</template>

<script>
import PaginationComponent from '@components/pagination/pagination'
import { mapGetters, mapActions } from 'vuex'
import ProjectSidebar from '@views/project/components/project-sidebar/project-sidebar'

export default {
  components: {
    PaginationComponent,
    ProjectSidebar,
  },

  computed: {
    ...mapGetters({
      projectFiles: 'project/attachment/projectFiles',
      brandHub: 'project/attachment/brandHub',
      project: 'project/projectDetails',
      isMobileScreen: 'common/isMobileScreen',
      isMediumScreen: 'common/isMediumScreen',
    }),

    currentRouteName() {
      return this.$route.name
    },

    isProjectFilesRoute() {
      return (
        this.currentRouteName === 'project.details.uploaded-files.project-files'
      )
    },

    isBrandHubRoute() {
      return (
        this.currentRouteName === 'project.details.uploaded-files.brand-hub'
      )
    },
  },

  watch: {
    currentRouteName() {
      this.projectFilesPerPageUpdate(this.projectFiles.meta.per_page)
      this.brandHubPerPageUpdate(this.brandHub.meta.per_page)
    },
  },

  methods: {
    ...mapActions({
      getProjectFilesList: 'project/attachment/getProjectFilesList',
      getBrandHubList: 'project/attachment/getBrandHubList',
    }),

    projectFilesPageUpdate(page) {
      // check if the selected page is not more than the meta last page.
      const isSelectedPageValid = page <= this.projectFiles.meta.last_page

      this.getProjectFilesList({
        projectId: this.project.id,
        page: isSelectedPageValid ? page : this.projectFiles.meta.last_page,
        include: ['created_by'],
      })
    },

    brandHubPageUpdate(page) {
      // check if the selected page is not more than the meta last page.
      const isSelectedPageValid = page <= this.brandHub.meta.last_page

      this.getBrandHubList({
        projectId: this.project.id,
        page: isSelectedPageValid ? page : this.brandHub.meta.last_page,
        include: ['created_by'],
      })
    },

    projectFilesPerPageUpdate(perPage) {
      this.getProjectFilesList({
        projectId: this.project.id,
        page: 1,
        include: ['created_by'],
        perPage,
      })
    },

    brandHubPerPageUpdate(perPage) {
      this.getBrandHubList({
        projectId: this.project.id,
        page: 1,
        include: ['created_by'],
        perPage,
      })
    },
  },
}
</script>
