<template>
  <div class="vms-bulk-add-videos__video-names">
    <div class="row">
      <div
        v-for="(input, index) in bulkAddVideosQuantity"
        :key="index"
        class="col-9"
      >
        <div
          class="vms-bulk-add-videos__video-names--label vd-display-inline-block vd-black"
        >
          {{ input }}.
        </div>
        <input
          v-model="bulkAddVideoNamesArray[index]"
          class="vms-bulk-add-videos__video-names--input vd-display-inline-block vd-margin-bottom-20 vd-border-light-grey"
          type="text"
          name="bulkAddVideoNames[]"
          placeholder="Enter video name here"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, isEmpty, filter } from 'lodash'

export default {
  components: {},

  data() {
    return {
      bulkAddVideoNamesArray: [],
    }
  },

  computed: {
    ...mapGetters({
      bulkAddVideosQuantity: 'project/delivery/bulkAddVideosQuantity',
      bulkAddVideosNames: 'project/delivery/bulkAddVideosNames',
    }),
  },

  watch: {
    bulkAddVideoNamesArray(val) {
      let array = cloneDeep(val)
      let filteredArray = filter(array, function (o) {
        return !isEmpty(o)
      })

      this.setBulkAddVideosNames(filteredArray)
    },
  },

  methods: {
    ...mapActions({
      setBulkAddVideosNames: 'project/delivery/setBulkAddVideosNames',
    }),
  },
}
</script>
