var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vms-project-tab__header container-fluid vd-background-aqua vd-padding-top-small vd-padding-bottom-small"},[_c('div',{staticClass:"row no-gutters vms-project-tab__controls-wrapper"},[_c('div',{class:[
          'row no-gutters vms-project-tab__filters',
          _vm.isMobileScreen
            ? 'vms-project-tab__filters--mobile vd-margin-top-extra-small vd-margin-bottom-small'
            : 'vd-margin-left-small vd-margin-right-small' ]},[_c('search-component',{attrs:{"custom-class":"vms-project-tab__filters--search"},on:{"enter:search":_vm.activityLogsSearchUpdate}}),_c('div',{class:[
            _vm.isMobileScreen
              ? 'vd-margin-top-extra-small vd-margin-bottom-small'
              : 'vd-margin-left-small vd-margin-right-small' ]},[_c('sort-component',{attrs:{"sort-options":_vm.sortOptions,"custom-class":"vms-project-tab__filters--sort","custom-dropdown-list-class":"vms-project-tab__filters--sort__option"},on:{"update:sort":_vm.activityLogsSortUpdate}})],1)],1),_c('div',{staticClass:"vms-project-tab__pagination vd-margin-top-medium vd-margin-bottom-medium"},[_c('pagination-component',{attrs:{"total-rows":_vm.activityLogs.meta.total,"per-page":_vm.activityLogs.meta.per_page,"last-page":_vm.activityLogs.meta.last_page,"page":_vm.selectedPage,"show-page-in-route":false},on:{"update:page":_vm.currentPageUpdate,"update:per_page":_vm.activityLogsPerPageUpdate}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }