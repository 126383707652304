<template>
  <div class="vd-margin-top-extra-small">
    <project-health-action-component
      v-if="isProjectHealthHidden"
    ></project-health-action-component>

    <send-confirmation-email
      v-if="!isProjectCancelled"
    ></send-confirmation-email>

    <harvest-component :project="project">
      <text-popover
        slot="body"
        class="vd-display-inline-block"
        tooltip-text="Log time in Harvest"
        tooltip-icon="vd-icon-clock"
        tooltip-id="showProjectHarvestIcon"
      ></text-popover>
    </harvest-component>

    <reopen-project-component
      v-if="canReopenProject"
    ></reopen-project-component>

    <archive-project-component v-if="canArchiveProject" :project="project">
      <text-popover
        slot="body"
        class="vd-display-inline-block"
        tooltip-text="Archive project"
        tooltip-icon="vd-icon-archive"
        tooltip-id="archiveProjectTooltip"
      ></text-popover
    ></archive-project-component>

    <hold-project-component v-if="displayHoldButton" :project="project">
      <text-popover
        slot="body"
        class="vd-display-inline-block"
        tooltip-text="Put on hold"
        tooltip-icon="vd-icon-pause-circle-hover"
        tooltip-id="holdProjectTooltip"
      ></text-popover
    ></hold-project-component>
    <unhold-project-component v-else-if="isProjectOnHold" :project="project">
      <text-popover
        slot="body"
        class="vd-display-inline-block"
        tooltip-text="Take off hold"
        tooltip-icon="vd-icon-pause-red-dot-circle-hover"
        tooltip-id="unholdProjectTooltip"
      ></text-popover
    ></unhold-project-component>

    <cancel-project-component
      v-if="!isProjectCancelled"
    ></cancel-project-component>

    <eod-notes custom-class="vd-align-top">
      <div
        slot="body"
        class="vd-h3 vd-background-light-blue vd-white vd-padding-left-medium vd-padding-right-small vd-padding-bottom-extra-small vd-border-radius-50 vd-cursor-pointer"
      >
        <div
          :class="[
            'vms-project__actions--red-dot vd-h3 vd-display-flex vd-align-center vd-position-relative vd-padding-right-small vd-padding-top-extra-small',
            project.has_eod_notes ? 'vd-red-dot' : '',
          ]"
        >
          <i class="vd-icon icon-chat"></i>
          <span class="vd-h4 vd-margin-left-small"> Notes </span>
        </div>
      </div>
    </eod-notes>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import CancelProjectComponent from '@components/cancel-project/cancel-project'
import ReopenProjectComponent from '@components/reopen-project/reopen-project'
import ArchiveProjectComponent from '@components/archive-project/archive-project'
import HoldProjectComponent from '@components/hold-project/hold-project'
import UnholdProjectComponent from '@components/unhold-project/unhold-project'
import HarvestComponent from '@components/harvest/harvest'
import EodNotes from '@components/eod-notes/eod-notes'
import ProjectHealthActionComponent from '@views/project/components/project-health/project-health-action'
import SendConfirmationEmail from '@components/send-confirmation-email/send-confirmation-email'

export default {
  components: {
    TextPopover,
    HarvestComponent,
    CancelProjectComponent,
    ReopenProjectComponent,
    ArchiveProjectComponent,
    ProjectHealthActionComponent,
    SendConfirmationEmail,
    HoldProjectComponent,
    UnholdProjectComponent,
    EodNotes,
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      isProjectCancelled: 'project/isProjectCancelled',
      canReopenProject: 'project/canReopenProject',
      canArchiveProject: 'project/canArchiveProject',
      canHoldProject: 'project/canHoldProject',
      isProjectOnHold: 'project/isProjectOnHold',
      isProjectHealthHidden: 'project/project-health/isProjectHealthHidden',
    }),

    displayHoldButton() {
      return this.canHoldProject && !this.isProjectOnHold
    },
  },
}
</script>
