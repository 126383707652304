<template>
  <div
    v-click-outside="closeMenu"
    class="vms-project-header__dropdown vd-position-relative"
  >
    <div class="vd-text-align-right">
      <span
        id="toggleMenuSpan"
        class="vd-icon-three-dots-circle vd-bg-light-grey"
        @click="toggleMenu"
      ></span>
    </div>

    <div
      v-if="showMenu"
      class="vms-project-header__dropdown--menu vd-position-absolute vd-background-white vd-box-shadow vd-padding-medium"
    >
      <div class="row">
        <reopen-project-component
          v-if="canReopenProject"
          class="col-12 vms-project-header__dropdown--menu__item"
          show-text-only
        ></reopen-project-component>

        <archive-project-component
          v-if="canArchiveProject"
          class="col-12 vms-project-header__dropdown--menu__item"
          :project="project"
        >
          <span slot="body">Archive project</span>
        </archive-project-component>

        <cancel-project-component
          v-if="!isProjectCancelled"
          class="col-12 vms-project-header__dropdown--menu__item"
          show-text-only
        ></cancel-project-component>

        <hold-project-component
          v-if="displayHoldButton"
          class="col-12 vms-project-header__dropdown--menu__item"
          :project="project"
        >
          <span slot="body">Hold Project</span>
        </hold-project-component>

        <unhold-project-component
          v-else-if="isProjectOnHold"
          class="col-12 vms-project-header__dropdown--menu__item"
          :project="project"
        >
          <span slot="body">Unhold Project</span>
        </unhold-project-component>

        <eod-notes :custom-class="`col-12`">
          <div slot="body" class="vms-project-header__dropdown--menu__item">
            Project notes
          </div>
        </eod-notes>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CancelProjectComponent from '@components/cancel-project/cancel-project'
import ReopenProjectComponent from '@components/reopen-project/reopen-project'
import ArchiveProjectComponent from '@components/archive-project/archive-project'
import HoldProjectComponent from '@components/hold-project/hold-project'
import UnholdProjectComponent from '@components/unhold-project/unhold-project'
import ClickOutside from 'vue-click-outside'
import EodNotes from '@components/eod-notes/eod-notes'

export default {
  directives: {
    ClickOutside,
  },

  components: {
    CancelProjectComponent,
    ReopenProjectComponent,
    ArchiveProjectComponent,
    HoldProjectComponent,
    UnholdProjectComponent,
    EodNotes,
  },

  data() {
    return {
      showMenu: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      isProjectCancelled: 'project/isProjectCancelled',
      canReopenProject: 'project/canReopenProject',
      canArchiveProject: 'project/canArchiveProject',
      canHoldProject: 'project/canHoldProject',
      isProjectOnHold: 'project/isProjectOnHold',
    }),

    displayHoldButton() {
      return this.canHoldProject && !this.isProjectOnHold
    },
  },

  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },

    closeMenu() {
      this.showMenu = false
    },
  },
}
</script>

<style></style>
