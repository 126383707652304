<template>
  <div
    class="vms-project__bottom-menu vd-background-white vd-position-relative row no-gutters vd-border-light-grey"
  >
    <span
      id="toggleProjectHealthModalSpan"
      class="vms-project__bottom-menu--action vd-display-inline-block"
      @click="projectHealthClickHandler"
    >
      <project-health-action></project-health-action>
    </span>

    <span
      v-if="!isProjectCancelled"
      id="sendConfirmationEmailSpan"
      class="vms-project__bottom-menu--action vd-icon-email-forward"
      @click="sendConfirmationClickHandler"
    >
      <send-confirmation-email></send-confirmation-email>
    </span>

    <harvest-component
      :project="project"
      custom-parent-div-class="vms-project__bottom-menu--action"
    >
      <span
        id="closeProjectHealthSpan"
        slot="body"
        class="vd-display-inline-block vd-icon-clock"
        @click="closeProjectHealthModal"
      ></span>
    </harvest-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HarvestComponent from '@components/harvest/harvest'
import ProjectHealthAction from '@views/project/components/project-health/project-health-action'
import SendConfirmationEmail from '@components/send-confirmation-email/send-confirmation-email'

export default {
  components: {
    HarvestComponent,
    ProjectHealthAction,
    SendConfirmationEmail,
  },

  data() {
    return {
      showChat: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      isProjectCancelled: 'project/isProjectCancelled',
      showProjectHealthModal: 'project/project-health/showProjectHealthModal',
    }),
  },

  methods: {
    ...mapActions({
      toggleProjectHealthModalShow:
        'project/project-health/toggleProjectHealthModalShow',
    }),

    projectHealthClickHandler() {
      this.closeHarvest()
    },

    sendConfirmationClickHandler() {
      this.closeHarvest()
      this.closeProjectHealthModal()
    },

    closeProjectHealthModal() {
      if (this.showProjectHealthModal) {
        this.toggleProjectHealthModalShow()
      }
    },

    closeHarvest() {
      const harvest = document.querySelector('.harvest-overlay')
      if (harvest) {
        harvest.click()
      }
    },
  },
}
</script>
