<template>
  <div
    id="sendConfirmationEmailDiv"
    class="vd-display-inline-block"
    @click="sendActivateAndConfirmationEmail"
  >
    <text-popover
      class="vd-display-inline-block"
      tooltip-text="Send confirmation email"
      tooltip-icon="vd-icon-email-forward"
      tooltip-id="sendProjectConfirmationEmailDiv"
    ></text-popover>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'

export default {
  components: {
    TextPopover,
  },

  methods: {
    ...mapActions({
      sendActivateAndConfirmationEmail:
        'project/invoice/sendActivateAndConfirmationEmail',
    }),
  },
}
</script>
