var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vms-project-tab__header vd-border-light-grey vd-background-aqua',
    !_vm.isMediumScreen ? 'row no-gutters' : '' ]},[(!_vm.isMediumScreen)?_c('div',{staticClass:"vms-project-tab__header--invoices-left vd-dark-grey vd-padding-medium vd-border-light-grey"},[_vm._v(" Projects Accounting ")]):_c('project-sidebar',{attrs:{"sidebar-type":"invoices"}}),(_vm.project.has_draft_order)?_c('div',{class:[
      'vms-project-tab__header--invoices-right vd-black vd-background-cream  vd-padding-left-small vd-padding-right-small row no-gutters',
      _vm.isMediumScreen ? '' : 'vd-padding-right-100' ]},[_vm._m(0),_c('div',{staticClass:"vms-project-tab__header--activate-button vd-display-inline-block vd-text-align-right"},[_c('button',{staticClass:"vd-btn-medium vd-btn-blue vd-margin-bottom-small vd-margin-top-small",attrs:{"id":"sendActiveConfirmButton"},on:{"click":_vm.sendActivateAndConfirmationEmail}},[_vm._v(" Send & Activate ")])])]):_vm._e(),_c('div',{class:[
      'vms-project__add--container',
      _vm.isMediumScreen
        ? 'vms-project__add--container__mobile vms-project__add--container__mobile--top'
        : '' ]},[_c('div',{class:[
        'vd-icon-plus-round-blue vms-project__add--icon',
        _vm.isMediumScreen ? 'vms-project__add--icon__mobile' : '' ],attrs:{"id":"openAddProjectCostDropdownDiv"},on:{"click":_vm.toggleInvoiceDropdown}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInvoicesDropdown),expression:"showInvoicesDropdown"}],class:[
        'vms-dropdown vms-project__add--dropdown vd-background-white vd-box-shadow',
        _vm.isMediumScreen ? 'vms-project__add--dropdown__mobile' : '' ]},[_c('div',{staticClass:"vms-dropdown__list vms-project__add--dropdown-list vd-padding-medium",attrs:{"id":"createInvoiceProductionCostDiv"},on:{"click":_vm.handleAddProductionCost}},[_vm._v(" Add Production Cost ")]),_c('div',{staticClass:"vms-dropdown__list vms-project__add--dropdown-list vd-padding-medium",attrs:{"id":"createInvoiceDisbursementDiv"},on:{"click":_vm.handleAddDisbursementCost}},[_vm._v(" Add Disbursement ")])])]),(_vm.showAddProductionCostModal)?_c('add-production-cost-modal'):_vm._e(),(_vm.showAddDisbursementCostModal)?_c('add-disbursement-cost-modal'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-project-tab__header--activate-label vd-display-inline-block vd-padding-small row no-gutters"},[_c('span',[_c('div',{staticClass:"vms-project-tab__header--activate-bulb vd-icon-light-bulb vd-margin-right-small"})]),_vm._v(" Clicking 'Send & Activate' will send a confirmation email and activate the project. ")])}]

export { render, staticRenderFns }