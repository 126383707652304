var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vd-position-relative"},[(_vm.showLeftScrollButton)?_c('button',{staticClass:"vms-project__scroll-nav vms-project__scroll-nav--left vd-position-absolute vd-background-aqua vd-border-light-grey vd-border-width-1 vd-padding-left-small vd-padding-right-small vd-box-shadow",on:{"click":function($event){return _vm.updateProjectMenuScroll(-_vm.scrollStep)}}},[_c('span',{staticClass:"vms-project__scroll-nav--icon vd-icon-arrow-left-grey-black"})]):_vm._e(),_c('div',{ref:"projectMenuDiv",staticClass:"vms-project__menu vd-padding-right-40",on:{"scroll":_vm.toggleScrollButtons}},[_c('div',{staticClass:"vms-project__menu--nav vd-padding-right-40"},[_c('router-link',{class:[
          'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
          _vm.isProjectSummarySelected
            ? 'active vd-black vd-border-light-blue'
            : 'vd-darker-grey' ],attrs:{"id":"projectSummaryTabLink","to":{ name: 'project.details.summary' }}},[_vm._v(" Project Summary ")])],1),_c('div',{staticClass:"vms-project__menu--nav vd-padding-right-40"},[(!_vm.isTabMenuDisabled)?_c('router-link',{class:[
          'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
          _vm.isPreProductionSelected
            ? 'active vd-black vd-border-light-blue'
            : 'vd-darker-grey' ],attrs:{"id":"projectPreProductionTabLink","to":{ name: 'project.details.pre-production' }}},[_vm._v(" Pre-Production ")]):_c('div',{staticClass:"vd-light-grey"},[_vm._v("Pre-Production")])],1),_c('div',{staticClass:"vms-project__menu--nav vd-padding-right-40"},[(!_vm.isTabMenuDisabled)?_c('router-link',{class:[
          'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
          _vm.isFilmingDetailsSelected
            ? 'active vd-black vd-border-light-blue'
            : 'vd-darker-grey' ],attrs:{"id":"projectFilmingDetailsTabLink","to":{ name: 'project.details.filming-details' }}},[_vm._v(" Filming Details ")]):_c('div',{staticClass:"vd-light-grey"},[_vm._v("Filming Details")])],1),_c('div',{staticClass:"vms-project__menu--nav vd-padding-right-40"},[(!_vm.isTabMenuDisabled)?_c('router-link',{class:[
          'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
          _vm.isDeliveriesSelected
            ? 'active vd-black vd-border-light-blue'
            : 'vd-darker-grey' ],attrs:{"id":"projectDeliveriesTabLink","to":{ name: 'project.details.deliveries' }}},[_vm._v(" Deliveries ")]):_c('div',{staticClass:"vd-light-grey"},[_vm._v("Deliveries")])],1),_c('div',{staticClass:"vms-project__menu--nav vd-padding-right-40"},[(!_vm.isTabMenuDisabled)?_c('router-link',{class:[
          'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
          _vm.isScheduleSelected
            ? 'active vd-black vd-border-light-blue'
            : 'vd-darker-grey' ],attrs:{"id":"projectUploadedFilesTabLink","to":{ name: 'project.details.schedule' }}},[_vm._v(" Schedule ")]):_c('div',{staticClass:"vd-light-grey"},[_vm._v("Uploaded Files")])],1),_c('div',{staticClass:"vms-project__menu--nav vd-padding-right-40"},[(!_vm.isTabMenuDisabled)?_c('router-link',{class:[
          'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
          _vm.isUploadedFilesSelected
            ? 'active vd-black vd-border-light-blue'
            : 'vd-darker-grey' ],attrs:{"id":"projectUploadedFilesTabLink","to":{ name: 'project.details.uploaded-files.project-files' }}},[_vm._v(" Uploaded Files ")]):_c('div',{staticClass:"vd-light-grey"},[_vm._v("Uploaded Files")])],1),(_vm.projectHasActivityLogs)?_c('div',{staticClass:"vms-project__menu--nav vd-padding-right-40"},[(!_vm.isTabMenuDisabled)?_c('router-link',{class:[
          'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
          _vm.isProjectLogsSelected
            ? 'active vd-black vd-border-light-blue'
            : 'vd-darker-grey' ],attrs:{"id":"projectLogsTabLink","to":{ name: 'project.details.activity-logs' }}},[_vm._v(" Activity Logs ")]):_c('div',{staticClass:"vd-light-grey"},[_vm._v("Activity Logs")])],1):_vm._e(),_c('div',{staticClass:"vms-project__menu--nav vd-padding-right-40"},[(!_vm.isTabMenuDisabled)?_c('router-link',{class:[
          'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
          _vm.isInvoicesSelected
            ? 'active vd-black vd-border-light-blue'
            : 'vd-darker-grey' ],attrs:{"id":"projectInvoicesTabLink","to":{ name: 'project.details.invoices.overview' }}},[_vm._v(" Invoices ")]):_c('div',{staticClass:"vd-light-grey"},[_vm._v("Invoices")])],1)]),(_vm.showRightScrollButton)?_c('button',{staticClass:"vms-project__scroll-nav vms-project__scroll-nav--right vd-position-absolute vd-background-aqua vd-border-light-grey vd-border-width-1 vd-padding-left-small vd-padding-right-small vd-box-shadow",on:{"click":function($event){return _vm.updateProjectMenuScroll(_vm.scrollStep)}}},[_c('span',{staticClass:"vms-project__scroll-nav--icon vd-icon-arrow-right-grey-black"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }