<template>
  <div
    class="vms-project-tab__header vd-dark-grey vd-background-aqua vd-border-light-grey vd-position-relative vd-padding-top-medium vd-padding-bottom-medium"
  >
    <div class="container-fluid">
      <div class="row no-gutters vms-project-tab__header--filming">
        <div v-show="!isMobileScreen" class="vd-margin-right-medium">
          Filming details
        </div>

        <div
          :class="[
            'vms-project-tab__pagination',
            isMobileScreen ? 'vd-margin-right-50' : 'vd-margin-right-70',
          ]"
        >
          <pagination-component
            :total-rows="filmingDetails.meta.total"
            :per-page="filmingDetails.meta.per_page"
            :last-page="filmingDetails.meta.last_page"
            @update:page="currentPageUpdate"
            @update:per_page="filmingDetailsPerPageUpdate"
          ></pagination-component>
        </div>
      </div>
    </div>

    <div
      :class="[
        'vms-project__add--container',
        isMediumScreen ? 'vms-project__add--container__mobile' : '',
      ]"
    >
      <div
        id="openNewFilmingDetailsFormDiv"
        :class="[
          'vd-icon-plus-round-blue vms-project__add--icon',
          isMediumScreen ? 'vms-project__add--icon__mobile' : '',
        ]"
        @click="openNewFilmingDetailsForm"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PaginationComponent from '@components/pagination/pagination'
import SweetAlert from '@plugins/sweet-alert'
import { isEmpty } from 'lodash'

export default {
  components: {
    PaginationComponent,
  },

  data() {
    return {
      currentPage: 1,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      filmingDetails: 'project/shoot/filmingDetails',
      selectedFilmingDetails: 'project/shoot/selectedFilmingDetails',
      hasProjectProductType: 'project/hasProjectProductType',
      isProjectOnHold: 'project/isProjectOnHold',
      isMobileScreen: 'common/isMobileScreen',
      isMediumScreen: 'common/isMediumScreen',
    }),
  },

  methods: {
    ...mapActions({
      getProjectFilmingDetailsList:
        'project/shoot/getProjectFilmingDetailsList',
      updateSelectedFilmingDetails:
        'project/shoot/updateSelectedFilmingDetails',
      showAddNewFilmingDetailsForm:
        'project/shoot/showAddNewFilmingDetailsForm',
      showEditFilmingDetailsForm: 'project/shoot/showEditFilmingDetailsForm',
      updateCallSheetFormOpen: 'project/shoot/updateCallSheetFormOpen',
    }),

    openNewFilmingDetailsForm() {
      if (isEmpty(this.selectedFilmingDetails)) {
        if (!this.hasProjectProductType || this.isProjectOnHold) {
          const title = !this.hasProjectProductType
            ? 'Missing Product Type'
            : 'Project On Hold'
          const content = !this.hasProjectProductType
            ? `<div class="vd-margin-top-small">This project is missing a Product Type. <br><br> Before adding a production job please select a Product Type first.</div>`
            : `<div class="vd-margin-top-small">Take project off hold before adding a shoot job.</div>`

          SweetAlert.fire({
            title: title,
            html: content,
            showCancelButton: !this.hasProjectProductType,
            confirmButtonText: 'Okay',
          }).then((result) => {
            if (result.isConfirmed && !this.hasProjectProductType) {
              this.$router.push({
                name: 'project.details.summary',
                params: {
                  projectId: this.project.id,
                },
              })
            }
          })
        } else {
          this.showAddNewFilmingDetailsForm(true)
        }
      } else {
        this.resetEditFormState()
      }
    },

    resetEditFormState() {
      const vm = this
      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          vm.showEditFilmingDetailsForm(false)
          vm.updateCallSheetFormOpen(false)
          vm.updateSelectedFilmingDetails(null)
          vm.showAddNewFilmingDetailsForm(true)
        }
      })
    },

    currentPageUpdate(page) {
      // check if the selected page is not more than the meta last page
      const isSelectedPageValid = page <= this.filmingDetails.meta.last_page

      this.getProjectFilmingDetailsList({
        projectId: this.project.id,
        page: isSelectedPageValid ? page : this.filmingDetails.meta.last_page,
        include: ['product_element_type'],
      })
    },

    filmingDetailsPerPageUpdate(perPage) {
      this.getProjectFilmingDetailsList({
        projectId: this.project.id,
        page: 1,
        include: ['product_element_type'],
        perPage,
      })
    },
  },
}
</script>
