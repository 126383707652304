<template>
  <div
    id="cancelProjectDiv"
    class="vd-display-inline-block"
    @click="handleCancelProjectIconClick"
  >
    <span v-if="showTextOnly"> Cancel project </span>
    <text-popover
      v-else
      class="vd-display-inline-block"
      tooltip-text="Cancel project"
      tooltip-icon="vd-icon-prohibit"
      tooltip-id="cancelProjectTooltip"
    ></text-popover>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import SweetAlert from '@plugins/sweet-alert'
import { size, find, includes } from 'lodash'
import VmsConstants from '@configs/vms-constants'
import PermissionsMixin from '@mixins/permissions-mixin'

export default {
  components: {
    TextPopover,
  },

  mixins: [PermissionsMixin],
  props: {
    showTextOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      invoiceList: 'project/invoice/invoiceList',
      invoicePaidWithCredits: 'project/invoice/invoicePaidWithCredits',
    }),

    isInvoiced() {
      return (
        size(
          find(this.invoiceList, (invoiceListData) => {
            return (
              size(
                find(invoiceListData.invoices, (invoice) => {
                  return invoice.invoice_status_id !== ''
                })
              ) > 0 ||
              invoiceListData.order_invoice_status_id !==
                VmsConstants.orderInvoiceStatuses.ORDER_NOT_INVOICED
            )
          })
        ) > 0
      )
    },

    isProjectStatusAllowedForCancellation() {
      const allowedStatus = [
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT,
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_ACTIVE,
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_PRE_PRODUCTION,
      ]

      return includes(allowedStatus, this.project.project_status_id)
    },

    canCancelProject() {
      return (
        (this.isProjectStatusAllowedForCancellation ||
          this.can('order_allow_restricted_actions')) &&
        !this.isInvoiced &&
        this.invoicePaidWithCredits <= 0
      )
    },
  },

  methods: {
    ...mapActions({
      cancelProject: 'project/cancelProject',
    }),

    handleCancelProjectIconClick() {
      let alertContent = `<div class="vd-margin-top-small">Are you sure you want to cancel</div>
      <div class="vd-margin-top-small vd-margin-bottom-small"><strong>${this.project.name}</strong>?</div>
      <div class="vd-margin-top-large">You will no longer be able to use this project if cancelled.</div>`

      if (
        !this.isProjectStatusAllowedForCancellation &&
        !this.can('order_allow_restricted_actions')
      ) {
        alertContent =
          '<div class="vd-margin-top-small">Sorry, you cannot cancel this project.</div><div class="vd-margin-top-small">Please contact the accounts team for assistance.</div>'
      }

      if (this.isInvoiced || this.invoicePaidWithCredits > 0) {
        alertContent =
          '<div class="vd-margin-top-small">Sorry, you cannot cancel this project.</div><div class="vd-margin-top-small">Please delete all attached invoices and clear all credits then try again.</div>'
      }

      SweetAlert.fire({
        title: 'Project Cancellation',
        html: alertContent,
        showCancelButton: true,
        cancelButtonText: this.canCancelProject ? 'Cancel' : 'Close',
        showConfirmButton: this.canCancelProject,
        confirmButtonText: 'Cancel Project',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.canCancelProject) {
            this.cancelProject({
              id: this.project.id,
              name: this.project.name,
              includes: null,
            })
          }
        }
      })
    },
  },
}
</script>
