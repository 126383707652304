<template>
  <div>
    <div
      class="vms-input__bulk-checkbox vms-project-sub-header__checkbox vd-display-inline vd-padding-left-medium"
    >
      <input
        id="selectAllBulkDeliveryCheckbox"
        v-model="selectAll"
        class="vd-margin-right-small"
        type="checkbox"
      />
      <span
        id="deliveriesBulkDropdownIcon"
        v-click-outside="handleHideBulkOption"
        class="vms-project-sub-header__checkbox--dropdown vd-icon-arrow-down-grey-black"
        @click="handleBulkOptionClick"
      ></span>

      <div
        v-if="showBulkDropdownOptions"
        class="vms-dropdown vms-project-sub-header__checkbox--dropdown__container vd-box-shadow vd-background-white vd-border-radius-6"
      >
        <div
          id="allDeliveriesSelectDiv"
          class="vms-dropdown__list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium"
          @click="handleBulkAllClick"
        >
          All
        </div>
      </div>
    </div>

    <div class="vms-project-sub-header__actions">
      <div
        v-if="hasSelectedDeliveries"
        id="bulkDeliveryIconDiv"
        class="vd-display-inline-block vd-margin-left-small"
        @click="showDefaultAlertContent"
      >
        <text-popover
          tooltip-text="Bulk Delivery"
          tooltip-placement="top"
          tooltip-icon="vd-icon-paper-plane vms-project-sub-header__actions--select-all"
          tooltip-id="sendProjectBulkDeliveryDiv"
        ></text-popover>

        <div v-show="false" id="swalHTML">
          <deliveries-bulk-delivery-content
            class="vms-project-sub-header__deliveries--sweetalert"
            :view-more="viewMore"
          ></deliveries-bulk-delivery-content>
        </div>
      </div>
    </div>

    <div
      v-if="hasSelectedDeliveries"
      class="vms-project-sub-header__results vd-green"
    >
      <span v-if="deliveries.meta.to === selectedDeliveriesCount">All</span>
      <span>
        {{ selectedDeliveriesCount }}
        {{ selectedDeliveriesCount | pluralizeEnglish('video') }}
        <span v-if="selectedDeliveriesCount > 1">are</span> selected.</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import SweetAlert from '@plugins/sweet-alert'
import { size, map } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'
import AppConfig from '@configs/app-config'
import DeliveriesBulkDeliveryContent from '@views/project/deliveries/components/deliveries-bulk-delivery-content'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    TextPopover,
    DeliveriesBulkDeliveryContent,
  },

  directives: {
    ClickOutside,
  },

  mixins: [FiltersMixin],

  data() {
    return {
      selectAll: false,
      viewMore: false,
      viewLimit: 5,
      showBulkDropdownOptions: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      deliveries: 'project/delivery/deliveries',
      selectedDeliveryIds: 'project/delivery/selectedDeliveryIds',
      deliveredJobs: 'project/delivery/deliveredJobs',
      nonDeliveredJobs: 'project/delivery/nonDeliveredJobs',
      selectAllDeliveries: 'project/delivery/selectAllDeliveries',
    }),

    selectedDeliveriesCount() {
      return size(this.selectedDeliveryIds)
    },

    hasSelectedDeliveries() {
      return this.selectedDeliveriesCount > 0
    },

    hasDeliveredJobs() {
      return size(this.deliveredJobs) > 0
    },
  },

  watch: {
    selectAll() {
      this.setDeliveriesSelectAll(this.selectAll)
    },

    selectAllDeliveries() {
      this.selectAll = this.selectAllDeliveries
    },
  },

  methods: {
    ...mapActions({
      setDeliveriesSelectAll: 'project/delivery/setDeliveriesSelectAll',
    }),

    handleBulkOptionClick() {
      this.showBulkDropdownOptions = !this.showBulkDropdownOptions
    },

    handleHideBulkOption() {
      this.showBulkDropdownOptions = false
    },

    handleBulkAllClick() {
      this.selectAll = true
      this.handleBulkOptionClick()
    },

    handleBulkDelivery() {
      this.viewMore = false
      window.open(
        `${AppConfig.vmsApiUrl}/projects/${
          this.project.id
        }/view-delivery-email/${map(this.deliveredJobs, 'id').join(',')}`,
        '_self'
      )
    },

    /**
     * Function to display the bulk delivery sweetalert
     * The config used here are as follows:
     * title - the title to display for the popup
     * html - render the hidden content of the bulk delivery content component using document.getElementById('swalHTML').innerHTML.
     * showCancelButton - set to true by default for displaying the cancel button
     * showDenyButton - display a third button using the deny button type when there are more items to view
     * showConfirmButton - display the confirm button only when there are any videos ready for delivery
     * denyButtonText - the label we use for the third button
     * confirmButtonText - the default text for the confirm button
     * cancelButtonText - the text used for the cancel button depending if there are any videos ready for delivery
     * showClass - custom class for the sweetalert animation
     * customClass - custom classes for deny button, cancel button and confirmation button
     * preDeny - logic check when the third button is selected.
     */
    showBulkDeliveryAlert() {
      return SweetAlert.fire({
        title: 'Bulk Video Delivery',
        html: document.getElementById('swalHTML')
          ? document.getElementById('swalHTML').innerHTML
          : '',
        showCancelButton: true,
        showDenyButton:
          size(this.deliveredJobs) > this.viewLimit ||
          size(this.nonDeliveredJobs) > this.viewLimit,
        showConfirmButton: this.hasDeliveredJobs,
        denyButtonText: this.viewMore ? 'View Less' : 'View More',
        confirmButtonText: 'Continue',
        cancelButtonText: this.hasDeliveredJobs ? 'Cancel' : 'Close',
        isUpdatableParameter: true,
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '', // disable popup animation
        },
        customClass: {
          denyButton:
            'vms-project-sub-header__deliveries--sweetalert__actions--view-more vd-h5 vd-a',
          cancelButton:
            'vms-project-sub-header__deliveries--sweetalert__actions--cancel',
          confirmButton:
            'vms-project-sub-header__deliveries--sweetalert__actions--continue',
        },
        preDeny: () => {
          this.viewMore = !this.viewMore
        },
      })
    },

    showDefaultAlertContent() {
      this.showBulkDeliveryAlert('View More').then((result) => {
        if (result.isConfirmed) {
          this.handleBulkDelivery()
        } else if (result.isDismissed) {
          this.viewMore = false
        } else if (result.isDenied) {
          this.showViewMoreAlertContent()
        }
      })
    },

    showViewMoreAlertContent() {
      this.showBulkDeliveryAlert('View Less').then((result) => {
        if (result.isConfirmed) {
          this.handleBulkDelivery()
        } else if (result.isDismissed) {
          this.viewMore = false
        } else if (result.isDenied) {
          this.showViewMoreAlertContent()
        }
      })
    },
  },
}
</script>
