var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-project-tab__header vd-dark-grey vd-background-aqua vd-border-light-grey vd-position-relative vd-padding-top-medium vd-padding-bottom-medium"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{class:[
          'vd-border-grey vms-project-tab__sort--container vd-padding-medium',
          _vm.isMediumScreen ? 'vms-project-tab__sort--container__mobile' : '' ]},[_c('span',{staticClass:"vd-light-blue"},[_vm._v("Sort By: ")]),_c('span',{staticClass:"vd-black"},[_vm._v("All")]),_c('span',{staticClass:"vms-project-tab__sort--icons-down vd-icon-arrow-down-grey-black vd-margin-top-extra-small"})])])]),(!_vm.preProductionsLoading)?_c('div',{class:[
      'vms-project__add--container',
      _vm.isProjectMenuScrollable ? 'vms-project__add--container__mobile' : '' ]},[_c('div',{class:[
        'vd-icon-plus-round-blue vms-project__add--icon',
        _vm.isProjectMenuScrollable ? 'vms-project__add--icon__mobile' : '' ],attrs:{"id":"addPreProductionIconDiv"},on:{"click":_vm.handleAddPreProduction}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreProductionDropdown),expression:"showPreProductionDropdown"}],class:[
        'vms-dropdown vms-project__add--dropdown',
        _vm.isProjectMenuScrollable ? 'vms-project__add--dropdown__mobile' : '' ]},[_c('div',{staticClass:"vd-box-shadow vd-background-white vd-border-radius-6"},_vm._l((_vm.preProductionTypes),function(type){return _c('div',{key:type.id,staticClass:"vms-dropdown__list vd-black vd-padding-small",attrs:{"id":_vm.getDropdownId(type.product_element_type.name)},on:{"click":function($event){return _vm.handlePreProductionTypeClick(type)}}},[_vm._v(" "+_vm._s(type.product_element_type.name)+" ")])}),0)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }