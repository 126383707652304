<template>
  <div
    class="vms-project-tab__header vd-dark-grey vd-background-aqua vd-border-light-grey vd-position-relative vd-padding-top-medium vd-padding-bottom-medium"
  >
    <div class="container-fluid">
      <div class="row">
        <div
          :class="[
            'vd-border-grey vms-project-tab__sort--container vd-padding-medium',
            isMediumScreen ? 'vms-project-tab__sort--container__mobile' : '',
          ]"
        >
          <span class="vd-light-blue">Sort By: </span>
          <span class="vd-black">All</span>
          <span
            class="vms-project-tab__sort--icons-down vd-icon-arrow-down-grey-black vd-margin-top-extra-small"
          ></span>
        </div>
      </div>
    </div>

    <div
      v-if="!preProductionsLoading"
      :class="[
        'vms-project__add--container',
        isProjectMenuScrollable ? 'vms-project__add--container__mobile' : '',
      ]"
    >
      <div
        id="addPreProductionIconDiv"
        :class="[
          'vd-icon-plus-round-blue vms-project__add--icon',
          isProjectMenuScrollable ? 'vms-project__add--icon__mobile' : '',
        ]"
        @click="handleAddPreProduction"
      ></div>

      <div
        v-show="showPreProductionDropdown"
        :class="[
          'vms-dropdown vms-project__add--dropdown',
          isProjectMenuScrollable ? 'vms-project__add--dropdown__mobile' : '',
        ]"
      >
        <div class="vd-box-shadow vd-background-white vd-border-radius-6">
          <div
            v-for="type in preProductionTypes"
            :id="getDropdownId(type.product_element_type.name)"
            :key="type.id"
            class="vms-dropdown__list vd-black vd-padding-small"
            @click="handlePreProductionTypeClick(type)"
          >
            {{ type.product_element_type.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SweetAlert from '@plugins/sweet-alert'
import { camelCase, startCase } from 'lodash'

export default {
  data() {
    return {
      showPreProductionDropdown: false,
    }
  },

  computed: {
    ...mapGetters({
      preProductionTypes: 'project/pre-production/preProductionTypes',
      preProductionNewFormOpen:
        'project/pre-production/preProductionNewFormOpen',
      preProductionEditFormOpen:
        'project/pre-production/preProductionEditFormOpen',
      preProductionsLoading: 'project/pre-production/preProductionsLoading',
      hasProjectProductType: 'project/hasProjectProductType',
      isProjectMenuScrollable: 'project/isProjectMenuScrollable',
      isProjectOnHold: 'project/isProjectOnHold',
      isMediumScreen: 'common/isMediumScreen',
      isPreProductionChangesFormOpen:
        'project/pre-production/isPreProductionChangesFormOpen',
    }),

    projectId() {
      return this.$route.params.projectId
    },
  },

  mounted() {
    if (this.hasProjectProductType) {
      this.updatePreProductionTypesList({
        projectId: this.projectId,
        include: ['product_element_type'],
      })
    }
  },

  methods: {
    ...mapActions({
      updateSelectedPreProductionType:
        'project/pre-production/updateSelectedPreProductionType',
      updatePreProductionTypesList:
        'project/pre-production/updatePreProductionTypesList',
      showAddNewPreProductionsForm:
        'project/pre-production/showAddNewPreProductionsForm',
      showEditPreProductionsForm:
        'project/pre-production/showEditPreProductionsForm',
      updateSelectedPreProductionId:
        'project/pre-production/updateSelectedPreProductionId',
      resetPreProductionChanges:
        'project/pre-production/resetPreProductionChanges',
    }),

    handlePreProductionTypeClick(selectedType) {
      if (
        this.preProductionEditFormOpen ||
        this.isPreProductionChangesFormOpen
      ) {
        this.showWarningAlert(selectedType)
      } else {
        this.showAddNewForm(selectedType)
        this.resetPreProductionChanges()
      }

      this.showPreProductionDropdown = false
    },

    handleAddPreProduction() {
      if (this.preProductionNewFormOpen) {
        return
      }

      if (!this.hasProjectProductType || this.isProjectOnHold) {
        this.showPreProductionDropdown = false

        const title = !this.hasProjectProductType
          ? 'Missing Product Type'
          : 'Project On Hold'
        const content = !this.hasProjectProductType
          ? `<div class="vd-margin-top-small">This project is missing a Product Type. <br><br> Before adding a production job please select a Product Type first.</div>`
          : `<div class="vd-margin-top-small">Take project off hold before adding a pre-production job.</div>`

        SweetAlert.fire({
          title: title,
          html: content,
          showCancelButton: !this.hasProjectProductType,
          confirmButtonText: 'Okay',
        }).then((result) => {
          if (result.isConfirmed && !this.hasProjectProductType) {
            this.$router.push({
              name: 'project.details.summary',
              params: {
                projectId: this.projectId,
              },
            })
          }
        })
        return
      }

      this.showPreProductionDropdown = !this.showPreProductionDropdown
    },

    showAddNewForm(selectedType) {
      this.updateSelectedPreProductionType(selectedType)
      this.showAddNewPreProductionsForm(true)
    },

    showWarningAlert(selectedType) {
      SweetAlert.fire({
        title: 'Unsaved Changes',
        html: `<br> You have unsaved changes in progress. <br><br> Do you wish to discard them?`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.showAddNewForm(selectedType)
          this.showEditPreProductionsForm({
            show: false,
          })

          // Clear selected pre-production id
          this.updateSelectedPreProductionId(null)
          this.resetPreProductionChanges()
        }
      })
    },

    getDropdownId(name) {
      return `createPreProduction${startCase(camelCase(name)).replace(
        / /g,
        ''
      )}Div`
    },
  },
}
</script>
