<template>
  <div>
    <template v-if="isTooltipContent">
      <div
        v-if="displayProjectId"
        class="row no-gutters vd-margin-bottom-small"
      >
        <div class="col-5 vd-dark-grey">Project #:</div>
        <div class="col-6 offset-1">{{ project.id }}</div>
      </div>
      <div
        v-if="displayProjectDetails"
        class="row no-gutters vd-margin-bottom-small"
      >
        <div class="col-5 vd-dark-grey">Created:</div>
        <div class="col-6 offset-1">
          {{ project.created_at | unixToFormat('DD/MM/YYYY') }}
        </div>
      </div>
      <div class="row no-gutters vd-margin-bottom-small">
        <div class="col-5 vd-dark-grey">Company:</div>
        <div class="col-6 offset-1">
          <a class="vd-a" target="blank" :href="companyUrl">{{
            company.name | truncateString(40)
          }}</a>
        </div>
      </div>
      <div class="row no-gutters vd-margin-bottom-small">
        <div class="col-5 vd-dark-grey">Office:</div>
        <div class="col-6 offset-1">
          <a class="vd-a" target="blank" :href="officeUrl">{{
            office.name | truncateString(40)
          }}</a>
        </div>
      </div>
      <div
        v-if="selectedFootageStatusId"
        class="row no-gutters vd-margin-bottom-small"
      >
        <div class="col-5 vd-dark-grey">Footage Status:</div>
        <div class="col-6 offset-1">{{ footageStatusLabel }}</div>
      </div>
    </template>

    <template v-else>
      <div class="row vd-margin-top-extra-small no-gutters vd-h4">
        <template v-if="displayProjectId">
          <div class="vd-padding-right-10">
            <span class="vd-dark-grey">Project #: </span>
            <span class="vd-black">{{ project.id }}</span>
          </div>

          <div class="vd-padding-right-10">
            <span class="vd-dark-grey">|</span>
          </div>
        </template>

        <div v-if="displayProjectDetails">
          <span class="vd-dark-grey">Created: </span>
          <span class="vd-black">{{
            project.created_at | unixToFormat('DD/MM/YYYY')
          }}</span>
        </div>
      </div>

      <div class="row vd-margin-top-extra-small no-gutters vd-h4">
        <div class="vd-padding-right-10">
          <span class="vd-dark-grey">Company: </span>
          <a class="vd-a" target="blank" :href="companyUrl">{{
            company.name | truncateString(40)
          }}</a>
        </div>

        <div class="vd-padding-right-10">
          <span class="vd-dark-grey">Office: </span>
          <a class="vd-a" target="blank" :href="officeUrl">{{
            office.name | truncateString(40)
          }}</a>
        </div>

        <div
          v-if="isFootageStatusVisible"
          class="vd-padding-right-10 footage-status vd-field"
        >
          <span class="vd-dark-grey vd-margin-right-small"
            >Footage status:
          </span>
          <div
            v-if="hasPermissionToChangeFootageStatus && !isFootageStatusLoading"
            class="footage-status__select"
          >
            <model-list-select
              v-model="selectedFootageStatusId"
              name="selectedFootageStatusId"
              :list="footageStatusTypesList"
              option-value="id"
              option-text="name"
              :option-disabled="isFootageStatusLoading"
              placeholder="Select footage status"
              @input="handleFootageStatusUpdate"
            ></model-list-select>
          </div>
          <span v-else-if="isFootageStatusLoading" class="vd-dark-grey">
            Saving...
          </span>
          <a v-else class="vd-a" target="blank">{{
            footageStatusLabel | truncateString(40)
          }}</a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import { ModelListSelect } from 'vue-search-select'
import RolesConstants from '@configs/roles'
import ApiCustomIncludesConstant from '@configs/api-custom-includes'
import { find } from 'lodash'

export default {
  components: {
    ModelListSelect,
  },
  mixins: [FiltersMixin],

  props: {
    displayProjectId: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayProjectDetails: {
      type: Boolean,
      required: false,
      default: false,
    },

    isTooltipContent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selectedFootageStatusId: null,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      company: 'project/projectCompany',
      office: 'project/projectOffice',
      officeUrl: 'project/projectOfficeUrl',
      companyUrl: 'project/projectCompanyUrl',
      isFootageStatusLoading: 'project/isFootageStatusLoading',
      user: 'auth/getVmsUserData',
      footageStatusTypesList: 'footage-status-type/footageStatusTypesList',
    }),

    isFootageStatusVisible() {
      return (
        !this.isNewProject &&
        (this.selectedFootageStatusId ||
          this.hasPermissionToChangeFootageStatus)
      )
    },

    footageStatusLabel() {
      if (!this.selectedFootageStatusId) {
        return ''
      }

      const footageStatusObject = find(this.footageStatusTypesList, {
        id: this.selectedFootageStatusId,
      })

      return footageStatusObject ? footageStatusObject.name : ''
    },

    hasPermissionToChangeFootageStatus() {
      const mediaCoordinator = find(RolesConstants, {
        name: 'media_coordinator',
      })
      return find(this.user.roles, { id: mediaCoordinator.id })
    },

    isNewProject() {
      return !this.project.id
    },
  },

  mounted() {
    this.getFootageStatusTypes()
    this.selectedFootageStatusId = this.project.footage_status_type_id
  },

  methods: {
    ...mapActions({
      updateFootageStatus: 'project/updateFootageStatus',
      getFootageStatusTypes: 'footage-status-type/getFootageStatusTypes',
    }),

    handleFootageStatusUpdate() {
      if (
        this.selectedFootageStatusId &&
        this.selectedFootageStatusId !== this.project.footage_status_type_id
      ) {
        this.updateFootageStatus({
          project_name: this.project.name,
          project_id: this.project.id,
          footage_status_type_id: this.selectedFootageStatusId,
          customIncludes: ApiCustomIncludesConstant.projectDetails,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/components/project-header/project-info';
</style>
