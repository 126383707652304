<template>
  <div>
    <filming-details-header
      v-if="$route.name === 'project.details.filming-details'"
    ></filming-details-header>
    <summary-tab-header
      v-if="
        $route.name === 'project.details.summary' ||
        $route.name === 'project.details.reopen'
      "
    ></summary-tab-header>
    <files-tab-header v-if="isFilesRoute"></files-tab-header>
    <invoices-tab-header v-if="isInvoicesRoute"></invoices-tab-header>
    <pre-productions-tab-header
      v-if="$route.name === 'project.details.pre-production'"
    ></pre-productions-tab-header>
    <deliveries-tab-header
      v-if="$route.name === 'project.details.deliveries'"
    ></deliveries-tab-header>
    <logs-tab-header
      v-if="
        $route.name === 'project.details.activity-logs' &&
        projectHasActivityLogs
      "
    ></logs-tab-header>
    <schedule-tab-header
      v-if="$route.name === 'project.details.schedule'"
    ></schedule-tab-header>
  </div>
</template>

<script>
import FilmingDetailsHeader from '@views/project/components/tab-headers/filming-details-tab-header'
import summaryTabHeader from '@views/project/components/tab-headers/summary-tab-header'
import InvoicesTabHeader from '@views/project/components/tab-headers/invoices-tab-header'
import FilesTabHeader from '@views/project/components/tab-headers/files-tab-header'
import PreProductionsTabHeader from '@views/project/components/tab-headers/pre-productions-tab-header'
import DeliveriesTabHeader from '@views/project/components/tab-headers/deliveries-tab-header'
import LogsTabHeader from '@views/project/components/tab-headers/activity-logs-tab-header'
import ScheduleTabHeader from '@views/project/components/tab-headers/schedule-tab-header'
import { mapGetters } from 'vuex'

export default {
  components: {
    FilmingDetailsHeader,
    summaryTabHeader,
    InvoicesTabHeader,
    FilesTabHeader,
    PreProductionsTabHeader,
    DeliveriesTabHeader,
    LogsTabHeader,
    ScheduleTabHeader,
  },

  computed: {
    ...mapGetters({
      projectHasActivityLogs: 'project/activity-log/projectHasActivityLogs',
    }),

    isFilesRoute() {
      return (
        this.$route.name === 'project.details.uploaded-files' ||
        this.$route.name === 'project.details.uploaded-files.project-files' ||
        this.$route.name === 'project.details.uploaded-files.brand-hub'
      )
    },

    isInvoicesRoute() {
      return (
        this.$route.name === 'project.details.invoices' ||
        this.$route.name === 'project.details.invoices.overview' ||
        this.$route.name === 'project.details.invoices.list' ||
        this.$route.name === 'project.details.invoices.budget' ||
        this.$route.name === 'project.details.invoices.hubspot-quotes'
      )
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/project-tab-headers';
</style>
