<template>
  <div
    :class="[
      'vms-project-tab__header vd-border-light-grey vd-background-aqua',
      !isMediumScreen ? 'row no-gutters' : '',
    ]"
  >
    <div
      v-if="!isMediumScreen"
      class="vms-project-tab__header--invoices-left vd-dark-grey vd-padding-medium vd-border-light-grey"
    >
      Projects Accounting
    </div>

    <project-sidebar v-else sidebar-type="invoices"></project-sidebar>

    <div
      v-if="project.has_draft_order"
      :class="[
        'vms-project-tab__header--invoices-right vd-black vd-background-cream  vd-padding-left-small vd-padding-right-small row no-gutters',
        isMediumScreen ? '' : 'vd-padding-right-100',
      ]"
    >
      <div
        class="vms-project-tab__header--activate-label vd-display-inline-block vd-padding-small row no-gutters"
      >
        <span>
          <div
            class="vms-project-tab__header--activate-bulb vd-icon-light-bulb vd-margin-right-small"
          ></div>
        </span>
        Clicking 'Send & Activate' will send a confirmation email and activate
        the project.
      </div>
      <div
        class="vms-project-tab__header--activate-button vd-display-inline-block vd-text-align-right"
      >
        <button
          id="sendActiveConfirmButton"
          class="vd-btn-medium vd-btn-blue vd-margin-bottom-small vd-margin-top-small"
          @click="sendActivateAndConfirmationEmail"
        >
          Send & Activate
        </button>
      </div>
    </div>

    <div
      :class="[
        'vms-project__add--container',
        isMediumScreen
          ? 'vms-project__add--container__mobile vms-project__add--container__mobile--top'
          : '',
      ]"
    >
      <div
        id="openAddProjectCostDropdownDiv"
        :class="[
          'vd-icon-plus-round-blue vms-project__add--icon',
          isMediumScreen ? 'vms-project__add--icon__mobile' : '',
        ]"
        @click="toggleInvoiceDropdown"
      ></div>
      <div
        v-show="showInvoicesDropdown"
        :class="[
          'vms-dropdown vms-project__add--dropdown vd-background-white vd-box-shadow',
          isMediumScreen ? 'vms-project__add--dropdown__mobile' : '',
        ]"
      >
        <div
          id="createInvoiceProductionCostDiv"
          class="vms-dropdown__list vms-project__add--dropdown-list vd-padding-medium"
          @click="handleAddProductionCost"
        >
          Add Production Cost
        </div>
        <div
          id="createInvoiceDisbursementDiv"
          class="vms-dropdown__list vms-project__add--dropdown-list vd-padding-medium"
          @click="handleAddDisbursementCost"
        >
          Add Disbursement
        </div>
      </div>
    </div>

    <add-production-cost-modal
      v-if="showAddProductionCostModal"
    ></add-production-cost-modal>

    <add-disbursement-cost-modal
      v-if="showAddDisbursementCostModal"
    ></add-disbursement-cost-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { includes, forEach, isNil, isEmpty } from 'lodash'
import AddProductionCostModal from '@views/project/invoices/components/project-invoices-add-production-cost-modal'
import AddDisbursementCostModal from '@views/project/invoices/components/project-invoices-add-disbursement-cost-modal'
import SweetAlert from '@plugins/sweet-alert'
import VmsConstants from '@configs/vms-constants'
import ProjectSidebar from '@views/project/components/project-sidebar/project-sidebar'

export default {
  components: {
    AddProductionCostModal,
    AddDisbursementCostModal,
    ProjectSidebar,
  },

  data() {
    return {
      showInvoicesDropdown: false,
    }
  },

  computed: {
    ...mapGetters({
      showAddProductionCostModal: 'project/invoice/showAddProductionCostModal',
      showAddDisbursementCostModal:
        'project/invoice/showAddDisbursementCostModal',
      project: 'project/projectDetails',
      invoiceJobs: 'project/invoice/invoiceJobs',
      isProjectOnHold: 'project/isProjectOnHold',
      isMediumScreen: 'common/isMediumScreen',
    }),

    canAddCost() {
      let cantAddCost = false

      forEach(this.invoiceJobs, (job) => {
        if (
          job.order.order_invoicing_term_id ===
            VmsConstants.orderInvoiceTerms
              .ORDER_INVOICING_TERM_ID_50_PERCENT_UPFRONT_AND_COMPLETION &&
          includes(
            [
              VmsConstants.orderInvoiceStatuses.ORDER_UNDER_100_INVOICING,
              VmsConstants.orderInvoiceStatuses.ORDER_50_INVOICED,
            ],
            job.order.order_invoice_status_id
          )
        ) {
          cantAddCost = true
        }
      })

      return !cantAddCost
    },

    hasProductType() {
      return (
        !isEmpty(this.project.product_type) &&
        !isNil(this.project.product_type.id)
      )
    },

    invoicesRoutesData() {
      return [
        {
          id: 'viewInvoicesOverviewLink',
          to: 'project.details.invoices.overview',
          label: 'Overview',
        },
        {
          id: 'viewInvoicesListLink',
          to: 'project.details.invoices.list',
          label: 'Invoices',
        },
        {
          id: 'viewInvoiceBudgetLink',
          to: 'project.details.invoices.budget',
          label: 'Budget',
        },
        {
          id: 'viewInvoiceHubSpotQuotesLink',
          to: 'project.details.invoices.hubspot-quotes',
          label: 'HubSpot quotes',
        },
      ]
    },
  },

  methods: {
    ...mapActions({
      toggleAddProductionCostShowModal:
        'project/invoice/toggleAddProductionCostShowModal',
      toggleAddDisbursementCostShowModal:
        'project/invoice/toggleAddDisbursementCostShowModal',
      sendActivateAndConfirmationEmail:
        'project/invoice/sendActivateAndConfirmationEmail',
    }),

    handleAddProductionCost() {
      if (this.canAddCost) {
        this.toggleAddProductionCostShowModal()
      } else {
        this.triggerSweetAlert(
          'Warning!',
          `Please contact the Accounts team to modify the price of this 50-50 project.`
        )
      }

      this.showInvoicesDropdown = false
    },

    handleAddDisbursementCost() {
      if (this.canAddCost) {
        this.toggleAddDisbursementCostShowModal()
      } else {
        this.triggerSweetAlert(
          'Warning!',
          `Please contact the Accounts team to modify the price of this 50-50 project.`
        )
      }

      this.showInvoicesDropdown = false
    },

    toggleInvoiceDropdown() {
      if (!this.hasProductType || this.isProjectOnHold) {
        this.showInvoicesDropdown = false

        const title = !this.hasProductType
          ? 'Missing Product Type'
          : 'Project On Hold'
        const content = !this.hasProductType
          ? `<div class="vd-margin-top-small">Please select a Product Type before adding any costs to the project.</div>`
          : `<div class="vd-margin-top-small">Take project off hold before adding costs.</div>`

        this.triggerSweetAlert(title, content)
        return
      }
      this.showInvoicesDropdown = !this.showInvoicesDropdown
    },

    triggerSweetAlert(title, content) {
      SweetAlert.fire({
        title: title,
        html: content,
        showCancelButton: !this.hasProductType,
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed && !this.hasProductType) {
          this.$router.push({
            name: 'project.details.summary',
            params: {
              projectId: this.project.id,
            },
          })
        }
      })
    },
  },
}
</script>
