<template>
  <div
    class="vms-project-tab__header vd-dark-grey vd-background-aqua vd-border-light-grey vd-position-relative vd-padding-top-medium vd-padding-bottom-medium vd-margin-bottom-30"
  >
    <div class="container-fluid">
      <div class="row">
        <div v-if="!isMobileScreen" class="col-4">
          <span class="vms-project-tab__header">Summary</span>
        </div>

        <div :class="[isMobileScreen ? 'col-12' : 'col-8']">
          <div
            :class="[
              isMobileScreen ? 'vd-text-align-center' : 'vd-text-align-right',
            ]"
          >
            <div class="vd-display-inline-block">
              <b-progress
                :max="100"
                class="vms-project-tab__header--summary__progress-bar"
              >
                <b-progress-bar
                  :value="projectDetailsProgress"
                  :variant="getProgressVariant(projectDetailsProgress, 20, 70)"
                ></b-progress-bar>
              </b-progress>
            </div>

            <div
              class="vms-project-tab__header--summary__progress-bar--label vd-black vd-h4 vd-display-inline-block vd-margin-left-medium"
            >
              {{ projectDetailsProgress }}% completed
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BProgress, BProgressBar } from 'bootstrap-vue'

export default {
  components: {
    BProgress,
    BProgressBar,
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
      isMobileScreen: 'common/isMobileScreen',
    }),

    projectDetailsProgress() {
      let totalFields = 14
      let subtotal = 0

      if (this.projectDetails) {
        subtotal = this.increaseProgress(this.projectDetails.name, subtotal)

        if (this.projectDetails.product_type) {
          subtotal = this.increaseProgress(
            this.projectDetails.product_type.id,
            subtotal
          )
        }
        subtotal = this.increaseProgress(
          this.projectDetails.account_manager_id,
          subtotal
        )
        subtotal = this.increaseProgress(
          this.projectDetails.producer_id,
          subtotal
        )
        subtotal = this.increaseProgress(
          this.projectDetails.budgeted_hours,
          subtotal
        )
        subtotal = this.increaseProgress(
          this.projectDetails.start_date,
          subtotal
        )
        subtotal = this.increaseProgress(this.projectDetails.due_date, subtotal)

        subtotal = this.increaseProgress(this.projectDetails.goals, subtotal)
        subtotal = this.increaseProgress(this.projectDetails.platform, subtotal)
        subtotal = this.increaseProgress(this.projectDetails.topic, subtotal)
        subtotal = this.increaseProgress(
          this.projectDetails.target_audience,
          subtotal
        )
        subtotal = this.increaseProgress(
          this.projectDetails.video_example,
          subtotal
        )
        subtotal = this.increaseProgress(
          this.projectDetails.video_privacy,
          subtotal
        )
        subtotal = this.increaseProgress(
          this.projectDetails.additional_notes,
          subtotal
        )
      }
      return ((subtotal / totalFields) * 100).toFixed(0)
    },
  },

  methods: {
    getProgressVariant(value, warningStartPercent, successStartPercent) {
      let variant = 'danger'

      if (value >= warningStartPercent) {
        variant = 'warning'
      }

      if (value >= successStartPercent) {
        variant = 'success'
      }

      return variant
    },

    increaseProgress(type, subtotal) {
      return type ? subtotal + 1 : subtotal
    },
  },
}
</script>
