<template>
  <text-popover
    class="vd-display-inline-block"
    tooltip-id="projectOrdersTooltip"
    custom-tooltip-class="vms-popover__project-orders--icon vd-margin-top-small"
    tooltip-trigger="click"
    tooltip-placement="right"
    tooltip-text="Associated Orders"
  >
    <div
      slot="popover-body"
      class="vms-project-header__popover vd-padding-small"
    >
      <template v-if="displayProjectInfo">
        <project-info
          :display-project-id="displayProjectId"
          :display-project-details="displayProjectDetails"
          :is-tooltip-content="true"
        ></project-info>

        <hr v-if="displayProjectDetails && hasOrders" />
      </template>

      <div v-if="displayProjectDetails">
        <div class="vd-h4 vd-margin-bottom-small">
          <strong>Associated Orders</strong>
        </div>
        <div
          v-for="order in project.project_orders"
          :key="order.id"
          class="row no-gutters"
        >
          <div class="col-5 vd-dark-grey">Order ID:</div>
          <div class="col-6 offset-1">{{ order.id }}</div>
        </div>
      </div>
    </div>
  </text-popover>
</template>

<script>
import { mapGetters } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import ProjectInfo from '@views/project/components/project-header/project-info'

export default {
  components: {
    TextPopover,
    ProjectInfo,
  },
  props: {
    displayProjectInfo: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayProjectId: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayProjectDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      hasOrders: 'project/hasOrders',
      project: 'project/projectDetails',
    }),
  },
}
</script>
