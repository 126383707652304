<template>
  <div>
    <div class="row no-gutters">
      <div class="vd-margin-right-20 flex-fill">Project management</div>
      <div class="vd-text-align-right">
        {{ projectHealth.totalProjectManagement | moneyFormat }}
      </div>
    </div>
    <div class="row no-gutters">
      <div class="vd-margin-right-20 flex-fill">Production cost</div>
      <div class="vd-text-align-right">
        {{ projectHealth.totalProductionCost | moneyFormat }}
      </div>
    </div>
    <div class="row no-gutters">
      <div class="vd-margin-right-20 flex-fill">Disbursements</div>
      <div class="vd-text-align-right">
        {{ projectHealth.totalDisbursementCost | moneyFormat }}
      </div>
    </div>
    <div class="row no-gutters">
      <div class="vd-margin-right-20 flex-fill">
        <strong>Total price</strong>
      </div>
      <div class="vd-text-align-right">
        <strong>{{ project.total_price | moneyFormat }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      projectHealth: 'project/project-health/projectHealthSummary',
    }),
  },
}
</script>
