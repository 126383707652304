<template>
  <div
    :class="[customClass, 'vd-display-inline-block vd-position-relative']"
    @click="toggleEodNotesModal(true)"
  >
    <slot name="body"></slot>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    ...mapActions({
      toggleEodNotesModal: 'project/toggleEodNotesModal',
    }),
  },
}
</script>
