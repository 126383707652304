<template>
  <div>
    <div class="vms-breadcrumb vd-margin-top-10">
      <a
        id="viewHomePageLink"
        class="vd-a vd-icon-home"
        :href="homePageUrl"
      ></a>
      <span
        class="vd-light-grey vd-margin-right-extra-small vd-margin-left-extra-small"
      >
        |
      </span>
      <a
        id="viewProjectsListLink"
        class="vd-a vd-dark-grey"
        :href="projectsListUrl"
        >Projects</a
      >
      <span
        v-if="!isProjectReopen"
        class="vd-light-grey vd-margin-right-extra-small vd-margin-left-extra-small"
      >
        |
      </span>
      <span v-if="!isProjectReopen" class="vd-black">{{
        project.name | truncateString(68)
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppConfig from '@configs/app-config'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
    }),

    isProjectReopen() {
      return this.$route.name === 'project.details.reopen'
    },

    projectsListUrl() {
      return `${AppConfig.vmsApiUrl}/projects`
    },

    homePageUrl() {
      return `${AppConfig.vmsApiUrl}`
    },
  },
}
</script>
