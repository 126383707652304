<template>
  <div class="vms-bulk-add-videos__summary">
    <div class="row">
      <div class="col-12 vd-black">
        <div class="vd-black vd-margin-bottom-20">
          You are about to create
          <strong
            >{{ bulkAddVideosQuantity }} {{ selectedVideoProduct.name }}</strong
          >
          videos.
        </div>
        <div
          v-if="showVideoNames"
          class="vd-black vd-margin-bottom-20 vms-bulk-add-videos__summary--names"
        >
          <div class="vd-margin-bottom-extra-small">Video names:</div>
          <div
            v-for="(input, index) in bulkAddVideosNames"
            :key="index"
            class="vd-padding-left-10 vd-margin-bottom-extra-small vd-blue"
          >
            {{ input }}
          </div>
        </div>
        <div class="vd-black">
          Please confirm the above details before clicking continue, these
          details cannot be modified once saved. You will be able to set
          scheduling details on the next step.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  computed: {
    ...mapGetters({
      selectedVideoProduct: 'project/delivery/selectedVideoProduct',
      bulkAddVideosQuantity: 'project/delivery/bulkAddVideosQuantity',
      bulkAddVideosNames: 'project/delivery/bulkAddVideosNames',
    }),

    showVideoNames() {
      return !isEmpty(this.bulkAddVideosNames)
    },
  },
}
</script>
