<template>
  <default-modal
    :class="['show', isMobileScreen ? 'vms-modal--fullscreen' : '']"
    modal-dialog-class="modal-md"
    content-class="vd-background-white "
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">Add Production Cost</div>
      <div class="vms-modal__header--close close vd-black">
        <span
          v-show="!addProductionCostLoading"
          @click="toggleAddProductionCostShowModal"
        >
          x
        </span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div class="row vd-padding-medium">
        <div class="col-12">
          <div class="vd-margin-bottom-20">
            <input-component
              type="text"
              name="productionCostName"
              label="Name"
              :required="true"
              :container-custom-classes="[
                setInputValidationClass('productionCostName'),
              ]"
              @formInput="handleInputUpdate($event, 'productionCostName')"
            ></input-component>
          </div>
        </div>
        <div class="col-12">
          <input-component
            v-model.number="productionCostPrice"
            type="number"
            name="productionCostPrice"
            label="Price"
            :required="true"
            prepend-text="$"
            :container-custom-classes="[
              setInputValidationClass('productionCostPrice'),
            ]"
            @formInput="handleInputUpdate($event, 'productionCostPrice')"
          ></input-component>
        </div>
      </div>
    </div>
    <div
      slot="modal-footer"
      class="modal-footer vd-text-align-right vd-margin-top-30"
    >
      <button
        class="vd-btn-small vd-btn-blue"
        :disabled="submitBtnDisabled"
        @click="handleAddProductionCostBtn"
      >
        {{ addProductionCostLoading ? 'Loading...' : 'Save' }}
      </button>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import FormMixin from '@mixins/forms-mixin'
import InputComponent from '@components/form/form-input'
import { required, integer, minValue } from 'vuelidate/lib/validators'

export default {
  components: {
    DefaultModal,
    InputComponent,
  },

  mixins: [FormMixin],

  data() {
    return {
      productionCostName: '',
      productionCostPrice: '',
    }
  },

  computed: {
    ...mapGetters({
      addProductionCostLoading: 'project/invoice/addProductionCostLoading',
      project: 'project/projectDetails',
      isMobileScreen: 'common/isMobileScreen',
    }),

    submitBtnDisabled() {
      return this.$v.$invalid || this.addProductionCostLoading
    },
  },

  validations: {
    productionCostName: {
      required,
    },

    productionCostPrice: {
      required,
      integer,
      minValue: minValue(1),
    },
  },

  methods: {
    ...mapActions({
      toggleAddProductionCostShowModal:
        'project/invoice/toggleAddProductionCostShowModal',
      addProductionCost: 'project/invoice/addProductionCost',
    }),

    handleAddProductionCostBtn() {
      const payload = {
        name: this.productionCostName,
        price: this.productionCostPrice,
        project_id: this.project.id,
      }

      this.addProductionCost(payload)
    },
  },
}
</script>
