export default Object.freeze({
  deliveries: [
    {
      sortBy: 'created_at',
      sort: 'desc',
      text: 'Created (Newest First)',
      selected: true,
    },
    {
      sortBy: 'created_at',
      sort: 'asc',
      text: 'Created (Oldest First)',
      selected: false,
    },

    {
      sortBy: 'name',
      sort: 'desc',
      text: 'Name (Descending)',
      selected: false,
    },
    {
      sortBy: 'name',
      sort: 'asc',
      text: 'Name (Ascending)',
      selected: false,
    },
    {
      sortBy: 'status',
      sort: 'desc',
      text: 'Status (Descending)',
      selected: false,
    },
    {
      sortBy: 'status',
      sort: 'asc',
      text: 'Status (Ascending)',
      selected: false,
    },
    {
      sortBy: 'production_date',
      sort: 'desc',
      text: 'Production Date (Newest First)',
      selected: false,
    },
    {
      sortBy: 'production_date',
      sort: 'asc',
      text: 'Production Date (Oldest First)',
      selected: false,
    },
    {
      sortBy: 'due_date',
      sort: 'desc',
      text: 'Due Date (Newest First)',
      selected: false,
    },
    {
      sortBy: 'due_date',
      sort: 'asc',
      text: 'Due Date (Oldest First)',
      selected: false,
    },
  ],

  activityLogs: [
    {
      sortBy: 'created_at',
      sort: 'desc',
      text: 'Created (Newest First)',
      selected: true,
    },
    {
      sortBy: 'created_at',
      sort: 'asc',
      text: 'Created (Oldest First)',
      selected: false,
    },
    {
      sortBy: 'user_name',
      sort: 'desc',
      text: 'User Name (Descending)',
      selected: false,
    },
    {
      sortBy: 'user_name',
      sort: 'asc',
      text: 'User Name (Ascending)',
      selected: false,
    },
    {
      sortBy: 'log_name',
      sort: 'desc',
      text: 'Log Name (Descending)',
      selected: false,
    },
    {
      sortBy: 'log_name',
      sort: 'asc',
      text: 'Log Name (Ascending)',
      selected: false,
    },
  ],
})
