<template>
  <div
    v-if="!isProjectHealthHidden"
    class="vd-border-width-1 vd-border-radius-10 vd-border-lightest-grey vd-box-shadow vd-padding-medium"
  >
    <div class="row no-gutters">
      <div class="col-10">
        <div class="row no-gutters">
          <div
            class="vms-project-health__icon--health vd-icon-health vd-margin-right-10"
          ></div>

          <span class="vms-project-health__title vd-h4"
            ><strong>Project Health</strong></span
          >
        </div>
      </div>

      <div
        class="col-2 vd-text-align-right"
        @click="toggleProjectHealthModalShow"
      >
        <div
          id="showProjectHealthModalDiv"
          class="vms-project-health__icon--expand vd-icon-expand"
        ></div>
      </div>
    </div>

    <div class="row no-gutters flex-nowrap vd-h5 text-nowrap">
      <div
        class="vd-margin-right-small vd-padding-left-0 vd-padding-extra-small"
      >
        <project-health-pricing-component></project-health-pricing-component>
      </div>

      <div
        class="vd-background-lightest-grey vd-padding-extra-small vd-border-radius-6"
      >
        <project-health-hours-component></project-health-hours-component>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import { mapGetters, mapActions } from 'vuex'
import ProjectHealthPricingComponent from '@views/project/components/project-health/project-health-pricing'
import ProjectHealthHoursComponent from '@views/project/components/project-health/project-health-hours'

export default {
  components: {
    ProjectHealthPricingComponent,
    ProjectHealthHoursComponent,
  },
  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      isProjectHealthHidden: 'project/project-health/isProjectHealthHidden',
    }),
  },

  methods: {
    ...mapActions({
      toggleProjectHealthModalShow:
        'project/project-health/toggleProjectHealthModalShow',
    }),
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/project-health';
</style>
