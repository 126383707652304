<template>
  <div class="vd-position-relative">
    <button
      v-if="showLeftScrollButton"
      class="vms-project__scroll-nav vms-project__scroll-nav--left vd-position-absolute vd-background-aqua vd-border-light-grey vd-border-width-1 vd-padding-left-small vd-padding-right-small vd-box-shadow"
      @click="updateProjectMenuScroll(-scrollStep)"
    >
      <span
        class="vms-project__scroll-nav--icon vd-icon-arrow-left-grey-black"
      ></span>
    </button>

    <div
      ref="projectMenuDiv"
      class="vms-project__menu vd-padding-right-40"
      @scroll="toggleScrollButtons"
    >
      <div class="vms-project__menu--nav vd-padding-right-40">
        <router-link
          id="projectSummaryTabLink"
          :to="{ name: 'project.details.summary' }"
          :class="[
            'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
            isProjectSummarySelected
              ? 'active vd-black vd-border-light-blue'
              : 'vd-darker-grey',
          ]"
        >
          Project Summary
        </router-link>
      </div>

      <div class="vms-project__menu--nav vd-padding-right-40">
        <router-link
          v-if="!isTabMenuDisabled"
          id="projectPreProductionTabLink"
          :to="{ name: 'project.details.pre-production' }"
          :class="[
            'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
            isPreProductionSelected
              ? 'active vd-black vd-border-light-blue'
              : 'vd-darker-grey',
          ]"
        >
          Pre-Production
        </router-link>

        <div v-else class="vd-light-grey">Pre-Production</div>
      </div>

      <div class="vms-project__menu--nav vd-padding-right-40">
        <router-link
          v-if="!isTabMenuDisabled"
          id="projectFilmingDetailsTabLink"
          :to="{ name: 'project.details.filming-details' }"
          :class="[
            'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
            isFilmingDetailsSelected
              ? 'active vd-black vd-border-light-blue'
              : 'vd-darker-grey',
          ]"
        >
          Filming Details
        </router-link>

        <div v-else class="vd-light-grey">Filming Details</div>
      </div>

      <div class="vms-project__menu--nav vd-padding-right-40">
        <router-link
          v-if="!isTabMenuDisabled"
          id="projectDeliveriesTabLink"
          :to="{ name: 'project.details.deliveries' }"
          :class="[
            'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
            isDeliveriesSelected
              ? 'active vd-black vd-border-light-blue'
              : 'vd-darker-grey',
          ]"
        >
          Deliveries
        </router-link>

        <div v-else class="vd-light-grey">Deliveries</div>
      </div>

      <div class="vms-project__menu--nav vd-padding-right-40">
        <router-link
          v-if="!isTabMenuDisabled"
          id="projectUploadedFilesTabLink"
          :to="{ name: 'project.details.schedule' }"
          :class="[
            'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
            isScheduleSelected
              ? 'active vd-black vd-border-light-blue'
              : 'vd-darker-grey',
          ]"
        >
          Schedule
        </router-link>

        <div v-else class="vd-light-grey">Uploaded Files</div>
      </div>

      <div class="vms-project__menu--nav vd-padding-right-40">
        <router-link
          v-if="!isTabMenuDisabled"
          id="projectUploadedFilesTabLink"
          :to="{ name: 'project.details.uploaded-files.project-files' }"
          :class="[
            'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
            isUploadedFilesSelected
              ? 'active vd-black vd-border-light-blue'
              : 'vd-darker-grey',
          ]"
        >
          Uploaded Files
        </router-link>

        <div v-else class="vd-light-grey">Uploaded Files</div>
      </div>

      <div
        v-if="projectHasActivityLogs"
        class="vms-project__menu--nav vd-padding-right-40"
      >
        <router-link
          v-if="!isTabMenuDisabled"
          id="projectLogsTabLink"
          :to="{ name: 'project.details.activity-logs' }"
          :class="[
            'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
            isProjectLogsSelected
              ? 'active vd-black vd-border-light-blue'
              : 'vd-darker-grey',
          ]"
        >
          Activity Logs
        </router-link>

        <div v-else class="vd-light-grey">Activity Logs</div>
      </div>

      <div class="vms-project__menu--nav vd-padding-right-40">
        <router-link
          v-if="!isTabMenuDisabled"
          id="projectInvoicesTabLink"
          :to="{ name: 'project.details.invoices.overview' }"
          :class="[
            'vms-project__menu--nav-item vd-padding-bottom-extra-small vd-a vd-padding-left-0 vd-padding-right-0',
            isInvoicesSelected
              ? 'active vd-black vd-border-light-blue'
              : 'vd-darker-grey',
          ]"
        >
          Invoices
        </router-link>

        <div v-else class="vd-light-grey">Invoices</div>
      </div>
    </div>

    <button
      v-if="showRightScrollButton"
      class="vms-project__scroll-nav vms-project__scroll-nav--right vd-position-absolute vd-background-aqua vd-border-light-grey vd-border-width-1 vd-padding-left-small vd-padding-right-small vd-box-shadow"
      @click="updateProjectMenuScroll(scrollStep)"
    >
      <span
        class="vms-project__scroll-nav--icon vd-icon-arrow-right-grey-black"
      ></span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isNil, debounce } from 'lodash'

export default {
  data() {
    return {
      menuWidth: 0,
      showLeftScrollButton: false,
      showRightScrollButton: false,
      scrollStep: 300,
    }
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
      canReopenProject: 'project/canReopenProject',
      windowWidth: 'common/windowWidth',
      projectHasActivityLogs: 'project/activity-log/projectHasActivityLogs',
    }),

    isProjectReopen() {
      return this.$route.name === 'project.details.reopen'
    },

    isTabMenuDisabled() {
      return isNil(this.projectDetails.id) || this.isProjectReopen
    },

    isProjectSummarySelected() {
      return (
        this.isRouteActive('project.details.summary') ||
        this.isRouteActive('project.details') ||
        this.isRouteActive('project.details.reopen')
      )
    },

    isPreProductionSelected() {
      return this.isRouteActive('project.details.pre-production')
    },

    isFilmingDetailsSelected() {
      return this.isRouteActive('project.details.filming-details')
    },

    isDeliveriesSelected() {
      return this.isRouteActive('project.details.deliveries')
    },

    isUploadedFilesSelected() {
      return (
        this.isRouteActive('project.details.uploaded-files') ||
        this.isRouteActive('project.details.uploaded-files.project-files') ||
        this.isRouteActive('project.details.uploaded-files.brand-hub')
      )
    },

    isInvoicesSelected() {
      return (
        this.isRouteActive('project.details.invoices') ||
        this.isRouteActive('project.details.invoices.list') ||
        this.isRouteActive('project.details.invoices.overview') ||
        this.isRouteActive('project.details.invoices.budget') ||
        this.isRouteActive('project.details.invoices.hubspot-quotes')
      )
    },

    isProjectLogsSelected() {
      return this.isRouteActive('project.details.activity-logs')
    },

    isScheduleSelected() {
      return this.isRouteActive('project.details.schedule')
    },
  },

  watch: {
    /**
     * set project menu scroll position on route change
     */
    $route(val) {
      this.setProjectMenuScroll(val.meta?.scrollLeft)
    },

    /**
     * set project menu scroll position when windowWidth changed
     * Using debounce to make sure windowWidth, resize and scroll change is applied first
     */
    windowWidth: debounce(function () {
      this.setProjectMenuScroll(this.$route.meta?.scrollLeft)
      this.toggleScrollButtons()
    }, 100),

    /**
     * set project menu scroll position when projectHasActivityLogs changed
     */
    projectHasActivityLogs() {
      this.setProjectMenuScroll(this.$route.meta?.scrollLeft)
      setTimeout(() => {
        this.toggleScrollButtons()
      }, 1000)
    },
  },

  mounted() {
    this.setProjectMenuScroll(this.$route.meta?.scrollLeft)
    this.toggleScrollButtons()
  },

  beforeMount() {
    if (this.isProjectReopen && !this.canReopenProject) {
      this.$router.push({
        name: 'project.details.summary',
        projectId: this.projectDetails.id,
      })
    }
  },

  methods: {
    ...mapActions({
      setProjectMenuScrollable: 'project/setProjectMenuScrollable',
    }),

    isRouteActive(routeName) {
      return this.$route.name === routeName
    },

    /**
     * sets project menu scroll position
     * Using debounce to make sure projectGrid width changed first
     */
    setProjectMenuScroll: debounce(function (val) {
      if (this.$refs.projectMenuDiv) {
        this.$refs.projectMenuDiv.scrollTo({
          left: val,
          behavior: 'smooth',
        })
      }
    }, 100),

    /**
     * updates project menu scroll position
     */
    updateProjectMenuScroll(val) {
      if (this.$refs.projectMenuDiv) {
        this.setProjectMenuScroll(this.$refs.projectMenuDiv.scrollLeft + val)
      }
    },

    /**
     * show or hide scroll left & right button.
     */
    toggleScrollButtons() {
      const menu = this.$refs.projectMenuDiv
      if (menu) {
        this.showLeftScrollButton = menu.scrollLeft > 0
        // 40 from menu right padding. we need this space as buffer for add button and vertical scroll bar
        this.showRightScrollButton =
          menu.scrollWidth - 40 > menu.offsetWidth + menu.scrollLeft
        this.setProjectMenuScrollable(menu.scrollWidth > menu.offsetWidth)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/project-menu';
</style>
