<template>
  <default-modal
    class="show vms-project-health-modal"
    modal-dialog-class="vms-project-health-modal__dialog modal-md"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">Project Health</div>

      <div
        class="vms-modal__header--close close vd-black"
        @click="toggleProjectHealthModalShow"
      >
        <span id="closeProjectHealthModalSpan">x</span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div
        class="vd-margin-bottom-small vd-background-lightest-grey vd-border-radius-6 vd-padding-20"
      >
        <project-health-pricing></project-health-pricing>
        <hr />
        <project-health-hours></project-health-hours>
      </div>
      <div class="vd-padding-20">
        <project-health-jobs></project-health-jobs>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import DefaultModal from '@components/modal/default-modal'
import ProjectHealthPricing from '@views/project/components/project-health/project-health-pricing'
import ProjectHealthHours from '@views/project/components/project-health/project-health-hours'
import ProjectHealthJobs from '@views/project/components/project-health/project-health-jobs'

export default {
  components: {
    DefaultModal,
    ProjectHealthPricing,
    ProjectHealthHours,
    ProjectHealthJobs,
  },

  mixins: [FiltersMixin],

  methods: {
    ...mapActions({
      toggleProjectHealthModalShow:
        'project/project-health/toggleProjectHealthModalShow',
    }),
  },
}
</script>
