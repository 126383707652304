<template>
  <div>
    <div class="row no-gutters">
      <div class="col-4 offset-4 vd-text-align-right">Scheduled</div>
      <div class="col-4 vd-text-align-right">
        <span class="vd-margin-left-small"> Worked so far </span>
      </div>
    </div>

    <hr />

    <div class="row no-gutters">
      <div class="col-4">Pre-Production</div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.preProductionScheduledHours | numberFormat }}
      </div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.preProductionWorkedHours | numberFormat }}
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-4">Shooting</div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.shootScheduledHours | numberFormat }}
      </div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.shootWorkedHours | numberFormat }}
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-4">Editing</div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.editingScheduledHours | numberFormat }}
      </div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.editingWorkedHours | numberFormat }}
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-4">Animation</div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.animationScheduledHours | numberFormat }}
      </div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.animationWorkedHours | numberFormat }}
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-4">Changes</div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.changesScheduledHours | numberFormat }}
      </div>
      <div class="col-4 vd-text-align-right">
        {{ projectHealth.changesWorkedHours | numberFormat }}
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-4"><strong>Total Hours</strong></div>
      <div class="col-4 vd-text-align-right">
        <strong>{{ projectHealth.scheduledHours | numberFormat }}</strong>
      </div>
      <div class="col-4 vd-text-align-right">
        <strong>{{ projectHealth.totalHoursWorked | numberFormat }}</strong>
      </div>
    </div>
  </div>
</template>
<script>
import FiltersMixin from '@mixins/filters-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      projectHealth: 'project/project-health/projectHealthSummary',
    }),
  },
}
</script>
