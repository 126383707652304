<template>
  <div class="vms-range-slider">
    <VueSlideBar
      v-model="sliderValue"
      :data="sliderData"
      :range="sliderDataLabel"
      :process-style="processStyle"
    >
      <template slot="tooltip">
        <div class="vms-range-slider__marker"></div>
      </template>
    </VueSlideBar>
  </div>
</template>

<script>
import VueSlideBar from 'vue-slide-bar'

export default {
  components: {
    VueSlideBar,
  },

  props: {
    min: {
      type: Number,
      required: false,
      default: 0,
    },

    max: {
      type: Number,
      required: false,
      default: 20,
    },

    showLabelInterval: {
      type: Number,
      required: false,
      default: 5,
    },

    quantity: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      sliderValue: this.quantity,
      processStyle: {
        backgroundColor: '#00c5ff',
      },
    }
  },

  computed: {
    sliderData() {
      let data = []
      for (let value = this.min; value <= this.max; value++) {
        data.push(value)
      }

      return data
    },

    sliderDataLabel() {
      let data = []
      for (let value = this.min; value <= this.max; value++) {
        data.push({
          label: value,
          isHide: !(value % 5 === 0 || value === 1),
        })
      }

      return data
    },
  },

  watch: {
    sliderValue(val) {
      this.$emit('update:quantity', val)
    },

    quantity(val) {
      this.sliderValue = val
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/range-slider';
</style>
