export default Object.freeze([
  {
    id: 1,
    name: 'super_admin',
    label: 'Super Admin',
  },
  {
    id: 2,
    name: 'admin',
    label: 'VMS Admin',
  },
  {
    id: 3,
    name: 'account_manager',
    label: 'Relationship Manager',
  },
  {
    id: 4,
    name: 'production',
    label: 'Production',
  },
  {
    id: 5,
    name: 'accounts',
    label: 'Accounts',
  },
  {
    id: 6,
    name: 'media_coordinator',
    label: 'Media Coordinator',
  },
  {
    id: 7,
    name: 'shooter',
    label: 'Shooter',
  },
  {
    id: 8,
    name: 'editor',
    label: 'Editor',
  },
  {
    id: 9,
    name: 'equipment_manager',
    label: 'Equipment Manager',
  },
  {
    id: 10,
    name: 'producer',
    label: 'Producer',
  },
  {
    id: 11,
    name: 'director',
    label: 'Director',
  },
  {
    id: 12,
    name: 'client',
    label: 'User',
  },
  {
    id: 13,
    name: 'frontend_developer',
    label: 'Frontend Developer',
  },
  {
    id: 14,
    name: 'backend_developer',
    label: 'Backend Developer',
  },
  {
    id: 15,
    name: 'client_admin',
    label: 'Admin',
  },
  {
    id: 16,
    name: 'business_development_manager',
    label: 'BDM',
  },
  {
    id: 17,
    name: 'sales_development_representative',
    label: 'SDR',
  },
  {
    id: 18,
    name: 'animator',
    label: 'Animator',
  },
  {
    id: 19,
    name: 'pre_production',
    label: 'Pre-Production',
  },
  {
    id: 20,
    name: 'sub_contractor',
    label: 'Sub-Contractor',
  },
  {
    id: 21,
    name: 'accounting',
    label: 'Accounting',
  },
])
