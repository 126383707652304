<template>
  <div>
    <div
      v-if="hasDeliveredJobs"
      class="vms-project-sub-header__deliveries--list-included vd-border-light-grey vd-margin-top-medium vd-padding-bottom-medium"
    >
      The following videos will be delivered
      <br /><br />
      <ul
        class="vms-project-sub-header__deliveries--list vd-padding-0 vd-green"
      >
        <li v-for="deliveredJob in deliveredJobsList" :key="deliveredJob.id">
          {{ deliveredJob.name | truncateString(30) }}
        </li>
      </ul>

      <span
        v-if="deliveredJobsCount > viewLimit && !viewMore"
        class="vd-light-grey vd-h4"
        >+{{ remainingItemsToShow.deliveredJobs }} more</span
      >
    </div>

    <div v-if="hasNonDeliveredJobs" class="vd-margin-top-medium">
      <span>The following videos cannot be delivered</span>
      <br />
      <span class="vd-h5"
        >(Either no video exists yet or changes yet to be completed)</span
      >
      <br /><br />
      <ul class="vms-project-sub-header__deliveries--list vd-padding-0 vd-red">
        <li
          v-for="nonDeliveredJob in nonDeliveredJobsList"
          :key="nonDeliveredJob.id"
        >
          {{ nonDeliveredJob.name | truncateString(30) }}
        </li>
      </ul>

      <span
        v-if="nonDeliveredJobsCount > viewLimit && !viewMore"
        class="vd-light-grey vd-h4"
        >+{{ remainingItemsToShow.nonDeliveredJobs }} more</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { slice, size } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  props: {
    viewMore: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      viewLimit: 5,
    }
  },

  computed: {
    ...mapGetters({
      deliveredJobs: 'project/delivery/deliveredJobs',
      nonDeliveredJobs: 'project/delivery/nonDeliveredJobs',
    }),

    deliveredJobsCount() {
      return size(this.deliveredJobs)
    },

    nonDeliveredJobsCount() {
      return size(this.nonDeliveredJobs)
    },

    hasDeliveredJobs() {
      return this.deliveredJobsCount > 0
    },

    hasNonDeliveredJobs() {
      return this.nonDeliveredJobsCount > 0
    },

    deliveredJobsList() {
      return this.viewMore
        ? this.deliveredJobs
        : slice(this.deliveredJobs, 0, 5)
    },

    nonDeliveredJobsList() {
      return this.viewMore
        ? this.nonDeliveredJobs
        : slice(this.nonDeliveredJobs, 0, 5)
    },

    remainingItemsToShow() {
      return {
        deliveredJobs: this.deliveredJobsCount - this.viewLimit,
        nonDeliveredJobs: this.nonDeliveredJobsCount - this.viewLimit,
      }
    },
  },
}
</script>
