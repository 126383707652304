<template>
  <div class="vms-bulk-add-videos__video-quantity">
    <div class="row">
      <div class="col-9">
        <div class="vd-padding-top-20 vd-padding-bottom-20">
          <model-list-select
            id="bulkAddVideosProductInput"
            v-model="bulkAddVideosProduct"
            :list="videoProducts"
            option-value="product_id"
            option-text="name"
            placeholder="Select a product type"
          ></model-list-select>
        </div>
      </div>
      <div class="col-12">
        <div class="vd-darker-grey vd-h4">
          Please select the number of videos you wish to create in bulk
        </div>
      </div>
      <div class="col-9">
        <div class="vd-padding-left-10">
          <range-slider
            :min="1"
            :quantity="bulkAddVideosQuantity"
            @update:quantity="updateQuantity"
          ></range-slider>
        </div>
      </div>
      <div class="col-3 align-self-center">
        <div class="vd-padding-left-10">
          <input
            v-model.number="bulkAddVideosQuantityInput"
            class="vd-full-width vd-border-light-grey vd-border-radius-6 vd-padding-extra-small"
            type="number"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RangeSlider from '@components/range-slider/range-slider'
import { mapActions, mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'

export default {
  components: {
    ModelListSelect,
    RangeSlider,
  },

  data() {
    return {
      bulkAddVideosProduct: null,
      bulkAddVideosQuantityInput: 1,
    }
  },

  computed: {
    ...mapGetters({
      videoProducts: 'project/delivery/videoProducts',
      selectedVideoProduct: 'project/delivery/selectedVideoProduct',
      bulkAddVideosQuantity: 'project/delivery/bulkAddVideosQuantity',
    }),
  },

  watch: {
    bulkAddVideosQuantityInput(val) {
      this.setBulkAddVideosQuantity(val)
    },

    bulkAddVideosQuantity(val) {
      this.bulkAddVideosQuantityInput = val
    },

    bulkAddVideosProduct(val) {
      this.setSelectedVideoProductId(val)
    },
  },

  methods: {
    ...mapActions({
      setBulkAddVideosQuantity: 'project/delivery/setBulkAddVideosQuantity',
      setSelectedVideoProductId: 'project/delivery/setSelectedVideoProductId',
    }),

    updateQuantity(value) {
      this.setBulkAddVideosQuantity(value)
    },
  },
}
</script>
