<template>
  <default-modal
    :class="['show', isMobileScreen ? 'vms-modal--fullscreen' : '']"
    modal-dialog-class="modal-md"
    content-class="vd-background-white "
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">Add Disbursement Cost</div>
      <div class="vms-modal__header--close close vd-black">
        <span
          v-show="!addDisbursementCostLoading"
          @click="toggleAddDisbursementCostShowModal"
        >
          x
        </span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div v-if="addDisbursementCostLoading">
        <default-loader></default-loader>
      </div>
      <div v-else class="row vd-padding-medium">
        <div class="col-12">
          <div class="vd-margin-bottom-20">
            <input-component
              type="text"
              name="invoiceDisbursementName"
              label="Job Name"
              :required="true"
              :container-custom-classes="[
                setInputValidationClass('disbursementName'),
              ]"
              @formInput="handleInputUpdate($event, 'disbursementName')"
            ></input-component>
          </div>
        </div>
        <div class="col-12">
          <div class="vms-invoices__disbursement-cost">
            <div
              v-for="productElement in disbursementProductElements"
              :id="
                getDisbursementTypeIds(productElement.product_element_type.name)
              "
              :key="productElement.id"
              :class="[
                'vms-invoices__disbursement-cost--product-elements vd-cursor-pointer vd-padding-top-extra-small vd-padding-right-medium vd-padding-bottom-extra-small vd-padding-left-medium vd-margin-bottom-10 vd-margin-right-10 vd-border-radius-50',
                selectedProductElementId === productElement.id
                  ? 'vd-background-light-blue vd-white'
                  : 'vd-background-lightest-grey',
              ]"
              @click="selectedProductElementId = productElement.id"
            >
              {{ productElement.product_element_type.name }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <input-component
            v-model.number="disbursementPrice"
            type="number"
            name="disbursementPrice"
            label="Price"
            :required="true"
            prepend-text="$"
            :container-custom-classes="[
              setInputValidationClass('disbursementPrice'),
            ]"
            @formInput="handleInputUpdate($event, 'disbursementPrice')"
          ></input-component>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="modal-footer vd-margin-top-30">
      <div class="vd-text-align-right">
        <button
          class="vd-btn-small vd-btn-blue"
          :disabled="$v.$invalid"
          @click="handleAddDisbursementCostBtn"
        >
          {{ addDisbursementCostLoading ? 'Loading...' : 'Save' }}
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultLoader from '@components/loaders/default-loader'
import DefaultModal from '@components/modal/default-modal'
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'
import { required, integer, minValue } from 'vuelidate/lib/validators'
import { startCase, camelCase } from 'lodash'

export default {
  components: {
    DefaultLoader,
    DefaultModal,
    InputComponent,
  },

  mixins: [FormMixin],

  data() {
    return {
      disbursementName: '',
      disbursementPrice: '',
      selectedProductElementId: '',
    }
  },

  validations: {
    disbursementName: {
      required,
    },

    disbursementPrice: {
      required,
      integer,
      minValue: minValue(1),
    },

    selectedProductElementId: {
      required,
    },
  },

  computed: {
    ...mapGetters({
      addDisbursementCostLoading: 'project/invoice/addDisbursementCostLoading',
      disbursementProductElements:
        'project/invoice/disbursementProductElements',
      project: 'project/projectDetails',
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  beforeMount() {
    this.getDisbursementProductElements({
      customIncludes: ['product_element_type'],
    })
  },

  methods: {
    ...mapActions({
      toggleAddDisbursementCostShowModal:
        'project/invoice/toggleAddDisbursementCostShowModal',
      getDisbursementProductElements:
        'project/invoice/getDisbursementProductElements',
      addDisbursementCost: 'project/invoice/addDisbursementCost',
    }),

    handleAddDisbursementCostBtn() {
      const payload = {
        name: this.disbursementName,
        price: this.disbursementPrice,
        product_element_id: this.selectedProductElementId,
        project_id: this.project.id,
      }

      this.addDisbursementCost(payload)
    },

    getDisbursementTypeIds(name) {
      return `selectInvoiceDisbursementType${startCase(camelCase(name)).replace(
        / /g,
        ''
      )}Div`
    },
  },
}
</script>
