<template>
  <div>
    <div class="row no-gutters">
      <div class="vd-margin-right-20 flex-fill">Estimated hours</div>
      <div class="vd-text-align-right">{{ projectHealth.allocatedHours }}</div>
    </div>
    <div class="row no-gutters">
      <div class="vd-margin-right-20 flex-fill">Scheduled hours</div>
      <div class="vd-text-align-right">
        {{ projectHealth.scheduledHours | numberFormat }}
      </div>
    </div>
    <div class="row no-gutters">
      <div class="vd-margin-right-20 flex-fill">Worked hours</div>
      <div class="vd-text-align-right">
        {{ projectHealth.totalHoursWorked | numberFormat }}
      </div>
    </div>
    <div class="row no-gutters">
      <div class="vd-margin-right-20 flex-fill">
        <strong>Hourly rate</strong>
      </div>
      <div class="vd-text-align-right">
        <strong>{{ projectHealth.hourlyRate | moneyFormat }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      projectHealth: 'project/project-health/projectHealthSummary',
    }),
  },
}
</script>
