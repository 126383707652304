<template>
  <default-modal
    class="show"
    modal-dialog-class="modal-md"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-black">
        Please select the Office you would like to assign to this Project
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div v-if="confirmContactLoading">
        <default-loader></default-loader>
      </div>
      <div v-else class="row">
        <div class="col-12">Company</div>
        <div class="col-12 vd-light-blue vd-padding-bottom-10">
          {{ project.office.company.name }}
        </div>
        <div class="col-12">Contacts</div>
        <div class="col-12 vd-light-blue vd-padding-bottom-10">
          <div v-for="contact in contacts" :key="contact.id">
            {{ contact.first_name }} {{ contact.last_name }}
          </div>
        </div>
        <div class="col-12">Offices</div>
        <div class="col-12 vd-light-blue vd-padding-bottom-30">
          <model-list-select
            id="contactConfirmListInput"
            v-model="contactConfirmOfficeId"
            :list="officeList"
            option-value="id"
            option-text="name"
            placeholder="Select an office"
          ></model-list-select>
        </div>
        <div class="col-12 vd-text-align-right">
          <button
            id="saveConfirmContactButton"
            class="vd-btn-small vd-btn-blue"
            :disabled="isButtonDisabled"
            @click="handleConfirmContactBtn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import DefaultLoader from '@components/loaders/default-loader'
import FormMixin from '@mixins/forms-mixin'
import SweetAlert from '@plugins/sweet-alert'
import { ModelListSelect } from 'vue-search-select'
import { required } from 'vuelidate/lib/validators'
import { forEach, merge, keyBy, values, filter, find } from 'lodash'

export default {
  components: {
    DefaultModal,
    DefaultLoader,
    ModelListSelect,
  },

  mixins: [FormMixin],

  data() {
    return {
      contactConfirmOfficeId: null,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      contacts: 'project/contact/projectContacts',
      confirmContactLoading: 'project/confirmContactLoading',
    }),

    isButtonDisabled() {
      return this.$v.$invalid || this.confirmContactLoading
    },

    officeList() {
      let offices = []
      forEach(this.contacts, (contact) => {
        offices = merge(keyBy(offices, 'id'), keyBy(contact.offices, 'id'))
      })

      const filteredOffice = filter(values(offices), (office) => {
        return office.is_active
      })

      return values(filteredOffice)
    },

    selectedOfficeName() {
      const office = find(this.officeList, { id: this.contactConfirmOfficeId })

      return office ? office.name : ''
    },
  },

  validations: {
    contactConfirmOfficeId: {
      required,
    },
  },

  methods: {
    ...mapActions({
      confirmContactProject: 'project/confirmContactProject',
    }),

    handleConfirmContactBtn() {
      SweetAlert.fire({
        title: 'Confirm Office',
        html: `<br> Are you sure office "${this.selectedOfficeName}" is correct? <br>Once you save, you cannot change later.`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmContactProject({
            id: this.project.id,
            office_id: this.contactConfirmOfficeId,
          })
        }
      })
    },
  },
}
</script>
