<template>
  <div>
    <div
      class="vms-project-tab__header container-fluid vd-background-aqua vd-padding-top-small vd-padding-bottom-small"
    >
      <div class="row no-gutters vms-project-tab__controls-wrapper">
        <div
          :class="[
            'row no-gutters vms-project-tab__filters',
            isMobileScreen
              ? 'vms-project-tab__filters--mobile vd-margin-top-extra-small vd-margin-bottom-small'
              : 'vd-margin-left-small vd-margin-right-small',
          ]"
        >
          <search-component
            :custom-class="`vms-project-tab__filters--search`"
            @enter:search="activityLogsSearchUpdate"
          >
          </search-component>
          <div
            :class="[
              isMobileScreen
                ? 'vd-margin-top-extra-small vd-margin-bottom-small'
                : 'vd-margin-left-small vd-margin-right-small',
            ]"
          >
            <sort-component
              :sort-options="sortOptions"
              :custom-class="`vms-project-tab__filters--sort`"
              :custom-dropdown-list-class="`vms-project-tab__filters--sort__option`"
              @update:sort="activityLogsSortUpdate"
            >
            </sort-component>
          </div>
        </div>

        <div
          class="vms-project-tab__pagination vd-margin-top-medium vd-margin-bottom-medium"
        >
          <pagination-component
            :total-rows="activityLogs.meta.total"
            :per-page="activityLogs.meta.per_page"
            :last-page="activityLogs.meta.last_page"
            :page="selectedPage"
            :show-page-in-route="false"
            @update:page="currentPageUpdate"
            @update:per_page="activityLogsPerPageUpdate"
          ></pagination-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaginationComponent from '@components/pagination/pagination'
import { mapGetters, mapActions } from 'vuex'
import SortOptions from '@configs/sort-options'
import SearchComponent from '@components/search/search'
import SortComponent from '@components/sort/sort'

export default {
  components: {
    PaginationComponent,
    SortComponent,
    SearchComponent,
  },

  data() {
    return {
      sortOptions: SortOptions.activityLogs,
      selectedPage: 1,
      activityLogsPageFilter: {
        sort: '',
        sortBy: '',
        search: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      activityLogs: 'project/activity-log/activityLogs',
      projectDetails: 'project/projectDetails',
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  methods: {
    ...mapActions({
      getActivityLogs: 'project/activity-log/getActivityLogList',
    }),

    currentPageUpdate(page) {
      // check if the selected page is not more than the meta last page
      if (page <= this.activityLogs.meta.last_page) {
        this.selectedPage = page
      } else {
        this.selectedPage = this.activityLogs.meta.last_page
      }

      this.getActivityLogs({
        projectId: this.projectDetails.id,
        payload: {
          page: this.selectedPage,
          sort: this.activityLogsPageFilter.sort,
          sortBy: this.activityLogsPageFilter.sortBy,
          search: this.activityLogsPageFilter.search,
        },
      })
    },

    activityLogsPerPageUpdate(perPage) {
      this.selectedPage = 1

      this.getActivityLogs({
        projectId: this.projectDetails.id,
        payload: {
          page: this.selectedPage,
          sort: this.activityLogsPageFilter.sort,
          sortBy: this.activityLogsPageFilter.sortBy,
          search: this.activityLogsPageFilter.search,
          perPage,
        },
      })

      if (
        this.$route &&
        this.$route.query.page &&
        parseInt(this.$route.query.page) !== this.selectedPage
      ) {
        this.$router.replace({ query: { page: this.selectedPage } })
      }
    },

    activityLogsSortUpdate(sortOption) {
      this.activityLogsPageFilter.sort = sortOption.sort
      this.activityLogsPageFilter.sortBy = sortOption.sortBy
      this.selectedPage = 1

      this.getActivityLogs({
        projectId: this.projectDetails.id,
        payload: {
          page: this.selectedPage,
          sort: this.activityLogsPageFilter.sort,
          sortBy: this.activityLogsPageFilter.sortBy,
          search: this.activityLogsPageFilter.search,
        },
      })
    },

    activityLogsSearchUpdate(search) {
      this.activityLogsPageFilter.search = search
      this.selectedPage = 1

      this.getActivityLogs({
        projectId: this.projectDetails.id,
        payload: {
          page: this.selectedPage,
          sort: this.activityLogsPageFilter.sort,
          sortBy: this.activityLogsPageFilter.sortBy,
          search: this.activityLogsPageFilter.search,
        },
      })
    },
  },
}
</script>
