<template>
  <default-modal
    class="show"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-black">Bulk videos</div>
      <div class="vms-modal__header--close close vd-black">
        <span id="closeBulkVideoModalSpan" @click="toggleBulkAddVideosShowModal"
          >x</span
        >
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <bulk-add-videos-quantity
        v-show="showSection === 'quantity'"
      ></bulk-add-videos-quantity>
      <bulk-add-videos-names
        v-show="showSection === 'names'"
      ></bulk-add-videos-names>
      <bulk-add-videos-summary
        v-if="showSection === 'summary'"
      ></bulk-add-videos-summary>

      <div class="vms-bulk-add-videos__footer row">
        <div
          v-show="canGoBack || true"
          class="col-sm-auto vd-text-align-left align-self-center vd-black"
          @click="handleBackAction"
        >
          <div
            class="vms-bulk-add-videos__footer--icon-arrow vd-icon-arrow-left-grey-black"
          ></div>
          <span
            class="vms-bulk-add-videos__footer--back vd-padding-left-extra-small"
            >Back</span
          >
        </div>

        <div class="col vd-text-align-right">
          <button
            v-show="showSection === 'quantity'"
            id="viewNameYourVideosButton"
            class="vd-btn-small vd-btn-white vd-margin-right-10"
            :disabled="isBtnDisabled"
            @click="handleNameYourVideos"
          >
            Name your videos
          </button>

          <button
            v-show="canContinue"
            id="continueBulkAddButton"
            class="vd-btn-small vd-btn-blue"
            :disabled="isBtnDisabled"
            @click="handleContinueBtn"
          >
            Continue
          </button>
          <button
            v-show="!canContinue"
            id="confirmBulkAddButton"
            class="vd-btn-small vd-btn-blue vd-margin-top-medium"
            @click="handleConfirmBtn"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from '@components/modal/default-modal'
import BulkAddVideosQuantity from '@views/project/deliveries/components/deliveries-bulk-add-videos/deliveries-bulk-add-videos-quantity'
import BulkAddVideosNames from '@views/project/deliveries/components/deliveries-bulk-add-videos/deliveries-bulk-add-videos-names'
import BulkAddVideosSummary from '@views/project/deliveries/components/deliveries-bulk-add-videos/deliveries-bulk-add-videos-summary'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  components: {
    DefaultModal,
    BulkAddVideosQuantity,
    BulkAddVideosNames,
    BulkAddVideosSummary,
  },

  data() {
    return {
      showSection: 'quantity',
    }
  },

  computed: {
    ...mapGetters({
      videoProducts: 'project/delivery/videoProducts',
      selectedVideoProduct: 'project/delivery/selectedVideoProduct',
      bulkAddVideosQuantity: 'project/delivery/bulkAddVideosQuantity',
    }),

    isBtnDisabled() {
      return (
        isEmpty(this.selectedVideoProduct) ||
        this.bulkAddVideosQuantity < 1 ||
        this.bulkAddVideosQuantity > 20
      )
    },

    canGoBack() {
      return this.showSection === 'names' || this.showSection === 'summary'
    },

    canContinue() {
      return this.showSection === 'quantity' || this.showSection === 'names'
    },
  },

  methods: {
    ...mapActions({
      toggleBulkAddVideosShowModal:
        'project/delivery/toggleBulkAddVideosShowModal',
      setBulkAddVideosConfirm: 'project/delivery/setBulkAddVideosConfirm',
      showAddNewVideoDeliveryJobForm:
        'project/delivery/showAddNewVideoDeliveryJobForm',
    }),

    handleBackAction() {
      this.showSection = this.showSection === 'summary' ? 'names' : 'quantity'
    },

    handleNameYourVideos() {
      this.showSection = 'names'
    },

    handleContinueBtn() {
      this.showSection = 'summary'
    },

    handleConfirmBtn() {
      this.setBulkAddVideosConfirm(true),
        this.toggleBulkAddVideosShowModal(),
        this.showAddNewVideoDeliveryJobForm(true)
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/forms/select';
@import '@styles/plugins/vue-search-select';
</style>
