<template>
  <router-link
    id="projectReopenIconLink"
    class="vd-display-inline-block vd-black"
    :to="{ name: 'project.details.reopen' }"
  >
    <span v-if="showTextOnly"> Reopen project </span>
    <text-popover
      v-else
      class="vd-display-inline-block"
      tooltip-text="Reopen project"
      tooltip-icon="vd-icon-replay"
      tooltip-id="reopenProjectDiv"
    ></text-popover>
  </router-link>
</template>

<script>
import TextPopover from '@components/tooltips/text-popover'

export default {
  components: {
    TextPopover,
  },
  props: {
    showTextOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
